import { Route, RouteProps } from "react-router-dom";

import Home from "../pages/Home";
import Perfil from "../pages/Perfil";

import InnerAppContainer from "../components/InnerAppContainer";

import InnerAppContext from '../context/innerContext'

const PrivateRoutes: React.FC<RouteProps> = () => {
  return (
    <InnerAppContainer>
      <Route path="/" exact component={Home} />
      <Route path="/perfil" component={Perfil} />
    </InnerAppContainer>
  );
};

const InnerAppProvider = () => (
  <InnerAppContext>
    <PrivateRoutes></PrivateRoutes>
  </InnerAppContext>
)

export default InnerAppProvider;
