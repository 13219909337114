import { createContext, FC, useContext } from "react";
import { useSelector } from 'react-redux'

import { Store } from "../store";

import Api from '../services'

const InnerAppContext = createContext<InnerAppContextType | null>(null)

const InnerAppProvider: FC = ({ children }) => {

    // const domain = useSelector((state: Store) => state.auth.domain)
    const token = useSelector((state: Store) => state.auth.token)
    const env = useSelector((state: Store) => state.auth.environment)

    const api = new Api(env?.domain_url!, {
        Authorization: `Bearer ${token}`
    })

    return (
        <InnerAppContext.Provider value={{ api }}>
            {children}
        </InnerAppContext.Provider>
    )
}

export default InnerAppProvider

export const useApi = () => {
    const { api } = useContext(InnerAppContext) as InnerAppContextType
    return api
}

interface InnerAppContextType {
    api: Api
}

