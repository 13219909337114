import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
    root: {
        border: '2px solid #A5D9FF',
        boxShadow: '1px 2px 5px rgba(55, 55, 72, 0.08)',
        borderRadius: 12,
        marginTop: 8
    },
    accordionSummaryContent: {
        margin: '20px 0'
    },
    accordionDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        rowGap: 8,
        padding: '0 16px 16px'
    },
    checkbox: {
        padding: 0,
        color: '#373748'
    },
    deleteButton: {
        padding: 0
    }
}))

export const DistritoLabel = styled.p`
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const Hint = styled.p`
    font-size: 14px;
    color: #6B6878;
    text-align: left;
    margin-bottom: 24px;
`

export const EscolaContainer = styled.div`
    display: flex;
`

export const NomeEscola = styled.div`
    display: flex;
    align-items: center;    
    flex: 1;
`

export const ExcluirLabel = styled.span`
    color: #373748;
    font-size: 14px;
    margin-right: 13px;
    font-weight: bold;
    font-family: Ubuntu, sans-serif;
`

export const AccordionSummary = styled.div<{ expanded: boolean }>`
    display: flex;
    padding: 16px 16px ${props => props.expanded ? '0' : '16px'};
`

export const RemovingConfirmation = styled.div`
    font-weight: 500;
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 8px;
`

export const RemovingChoice = styled.span`
    cursor: pointer;
`

export default useStyles