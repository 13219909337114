/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import Scrollbar from "../../components/Scrollbar";

import DadosDeAcompanhamento from "./DadosDeAcompanhamento";
import InformacoesBasicas from "./InformacoesBasicas";
import Usuario from "./Usuario";

import { useApi } from "../../context/innerContext";
import { PerfilContext } from "./context";

import { Profile, Occupation } from "../../global";

import { Container, Content, Title, Subtitle, CardsContainer } from "./styles";

const Perfil = () => {
  const api = useApi()

  const [profile, setProfile] = useState<Profile>()
  const [relationships, setRelationships] = useState<any>()

  const getRelationships = (role: number) => {
    switch (role) {
      case Occupation.PROFESSOR:
        api.getProfessorRelationships()
          .then(({ data }) => {
            const relationships = [...data]

            relationships.forEach(({ turmas_disciplinas }) => turmas_disciplinas?.forEach(({ turma }) => delete turma['serie']))

            setRelationships(relationships)
          })
          .catch(() => alert('Houve um erro ao buscar as turmas do professor.'))
        break
      case Occupation.ASSESSOR:
        api.getAssessorRelationships()
          .then(({ data }) => setRelationships(data))
          .catch(() => alert('Houve um erro ao buscar os distritos do assessor.'))
        break
      default:
        api.getCoordenadorRelationships()
          .then(({ data }) => setRelationships(data))
          .catch(() => alert('Houve um erro ao buscar a escola do coordenador.'))
        break;
    }
  }

  useEffect(() => {
    api.getProfile()
      .then(({ data }) => {
        const { email, nome, username, cpf, data_nascimento, telefone_celular, occupation, occupation_display } = data

        setProfile({ email, nome, username, cpf, data_nascimento, telefone_celular, occupation, occupation_display })

        getRelationships(occupation)
      })
      .catch(() => alert('Houve um erro ao retornar os dados do perfil.'))
  }, [])

  const updateEmail = (email: string) => profile && setProfile({ ...profile, email })
  const updateInformacoesBasicas = ({ email, username, ...rest }: Profile) => profile && setProfile({ ...profile, ...rest })

  return (
    <Container>
      <Scrollbar autoHeight autoHeightMax="100vh">
        <Content>
          <Title>Perfil</Title>
          <Subtitle>Aqui você pode editar seus dados</Subtitle>
          {
            profile && (
              <PerfilContext.Provider value={{ updateEmail, updateInformacoesBasicas, profile, relationships: relationships!, updateRelationships: setRelationships }}>
                {/* <Grid container spacing={isMobile ? 0 : 3} item={!isMobile} xs>
                  <Grid container={isMobile} item={!isMobile} xs justifyContent="center">
                    <Usuario email={profile.email || 'E-mail não cadastrado'} username={profile.username} />
                    <InformacoesBasicas />
                  </Grid>
                  <Grid container={isMobile} item={!isMobile} xs={isMobile ? 12 : true} justifyContent="center">
                    <DadosDeAcompanhamento />
                  </Grid>                  
                </Grid> */}
                <CardsContainer>
                  <Usuario email={profile.email || 'E-mail não cadastrado'} username={profile.username} />
                  <InformacoesBasicas />
                  <DadosDeAcompanhamento />
                </CardsContainer>
              </PerfilContext.Provider>
            )
          }
        </Content>
      </Scrollbar>
    </Container>
  );
};

export default Perfil;