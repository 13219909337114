/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useMemo, useState } from "react";
import { Accordion, AccordionDetails, Card, CardContent, CardHeader, CardProps, Checkbox, FormControl, Grid, IconButton, InputLabel, ListItem, ListItemIcon, ListItemText, Select } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx'

import Button from "../Button";
import Modal from "../Modal";

import useIsMobile from '../../hooks/useIsMobile'

import { Disciplina, Escola, Turma, TurmaDisciplina } from "../../global";

import useStyles, { AccordionSummary, ContinueEditing, EscolaLabel, EscolaModalTitle, EscolaModalWarning, Hint, ModalButtonsContainer, SelectedTurmaContainer, TurmaDisciplinaLabel } from "./styles"

const EscolaCard = ({ escola, disciplinas, turmas, handleAddAllTurmasDisciplinas, handleRemoveEscola, handleAddTurmaDisciplina, handleAddAllDisciplinas, handleRemoveTurmaDisciplina, handleAddAllTurmas, ...rest }: EscolaCardProps) => {
    const isMobile = useIsMobile()

    const classes = useStyles()

    const ALL_ID = 999

    const allTurmas = {
        id: ALL_ID,
        identificador: 'Todas'
    }

    const allDisciplinas = {
        id: ALL_ID,
        nome: 'Todas'
    }

    const [selectedTurmas, setSelectedTurmas] = useState<Turma[]>([])
    const [selectedDisciplinas, setSelectedDisciplinas] = useState<Disciplina[]>([])

    const [removeEscolaModalIsOpen, setRemoveEscolaModalIsOpen] = useState(false)
    const [expanded, setExpanded] = useState(true)

    const uncheckAllCheckboxes = () => {
        setSelectedTurmas([])
        setSelectedDisciplinas([])
    }

    const addTurmasDisciplinas = () => {
        handleAddAllTurmasDisciplinas(selectedTurmas, selectedDisciplinas, escola)
        uncheckAllCheckboxes()
    }

    const removeEscola = () => {
        handleRemoveEscola(escola)
        setRemoveEscolaModalIsOpen(false)
    }

    const closeRemoveEscolaModal = () => setRemoveEscolaModalIsOpen(false)
    const openRemoveEscolaModal = () => setRemoveEscolaModalIsOpen(true)

    const closeAction = (onClick?: () => void) => (
        <IconButton onClick={onClick}>
            <CloseIcon></CloseIcon>
        </IconButton>
    )

    const renderTurmasMultipleSelect = () => {
        const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as Turma[];
            if (value.length && value[value.length - 1].id === ALL_ID) {
                setSelectedTurmas(selectedTurmas.length === turmas.length ? [] : turmas);
                return;
            }
            setSelectedTurmas(value);
        };

        const allTurmasAreSelected = turmas.length > 0 && selectedTurmas.length === turmas.length;

        return (
            <FormControl style={{ width: '100%' }}>
                <InputLabel id="turmas">Turmas</InputLabel>
                <Select
                    labelId="turmas"
                    id="turmas-select"
                    multiple
                    value={selectedTurmas}
                    onChange={handleChange}
                    renderValue={(selectedTurmas) => (selectedTurmas as Turma[]).map(({ identificador }) => identificador).join(", ")}
                    MenuProps={{ classes: { paper: classes.paper } }}
                >
                    {
                        // @ts-ignore
                        <ListItem key={allTurmas.id} value={allTurmas}>
                            <ListItemIcon>
                                <Checkbox checked={allTurmasAreSelected} color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={allTurmas.identificador} />
                        </ListItem>
                    }
                    {turmas.map((turma) => (
                        // @ts-ignore
                        <ListItem key={turma.id} value={turma}>
                            <ListItemIcon>
                                <Checkbox checked={turma.id === ALL_ID ? allTurmasAreSelected : selectedTurmas.indexOf(turma) > -1} color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={turma.identificador} />
                        </ListItem>
                    ))}
                </Select>
            </FormControl >
        )
    }

    const renderDisciplinasMultipleSelect = () => {
        const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
            const value = event.target.value as Disciplina[];
            if (value.length && value[value.length - 1].id === ALL_ID) {
                setSelectedDisciplinas(selectedDisciplinas.length === disciplinas.length ? [] : disciplinas);
                return;
            }
            setSelectedDisciplinas(value);
        };

        const allDisciplinasAreSelected = disciplinas.length > 0 && selectedDisciplinas.length === disciplinas.length;

        return (
            <FormControl style={{ width: '100%' }}>
                <InputLabel id="disciplinas">Disciplinas</InputLabel>
                <Select
                    labelId="disciplinas"
                    id="disciplinas-select"
                    multiple
                    value={selectedDisciplinas}
                    onChange={handleChange}
                    renderValue={(selectedDisciplinas) => (selectedDisciplinas as Disciplina[]).map(({ nome }) => nome).join(", ")}
                >
                    {
                        // @ts-ignore
                        <ListItem key={allDisciplinas.id} value={allDisciplinas}>
                            <ListItemIcon>
                                <Checkbox checked={allDisciplinasAreSelected} color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={allDisciplinas.nome} />
                        </ListItem>
                    }
                    {disciplinas.map((disciplina) => (
                        // @ts-ignore
                        <ListItem key={disciplina.id} value={disciplina}>
                            <ListItemIcon>
                                <Checkbox checked={disciplina.id === ALL_ID ? allDisciplinasAreSelected : selectedDisciplinas.indexOf(disciplina) > -1} color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={disciplina.nome} />
                        </ListItem>
                    ))}
                </Select>
            </FormControl >
        )
    }

    const addButtonIsEnabled = useMemo(() => {
        return selectedTurmas.length && selectedDisciplinas.length
    }, [selectedTurmas, selectedDisciplinas])

    return (
        <Accordion expanded={expanded} className={classes.root}>
            <AccordionSummary expanded={expanded} style={{ cursor: 'pointer' }}>
                <EscolaLabel onClick={() => setExpanded(!expanded)}>{escola.nome}</EscolaLabel>
                <IconButton onClick={openRemoveEscolaModal}>
                    <CloseIcon></CloseIcon>
                </IconButton>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Hint>Adicione abaixo as turmas e matérias dadas nesta escola</Hint>
                <Grid container spacing={4}>
                    <Grid item xs style={{ maxWidth: '50%' }}>
                        {renderTurmasMultipleSelect()}
                    </Grid>
                    <Grid item xs style={{ maxWidth: '50%' }}>
                        {renderDisciplinasMultipleSelect()}
                    </Grid>
                </Grid>
                <Button className={classes.addButton} disabled={!addButtonIsEnabled} onClick={addTurmasDisciplinas}>Adicionar</Button>
                <div>
                    {escola.turmas_disciplinas!.map((td) => (
                        <SelectedTurmaContainer key={`${td.turma.id}-${td.disciplina.id}`}>
                            <TurmaDisciplinaLabel>{`${td.turma.identificador}, ${td.disciplina.nome}.`}</TurmaDisciplinaLabel>
                            <IconButton className={classes.iconButton} onClick={() => handleRemoveTurmaDisciplina(td, escola)}>
                                <CloseIcon></CloseIcon>
                            </IconButton>
                        </SelectedTurmaContainer>
                    ))}
                </div>
            </AccordionDetails>
            <Modal open={removeEscolaModalIsOpen}>
                <Card className={clsx(classes.modalCard, isMobile ? classes.modalCardMobileWidth : classes.modalCardWebWidth)}>
                    <CardHeader className={classes.modalCardHeader} action={closeAction(closeRemoveEscolaModal)}>
                    </CardHeader>
                    <CardContent className={classes.modalCardContent}>
                        <EscolaModalTitle>Deseja mesmo excluir a escola?</EscolaModalTitle>
                        <EscolaModalWarning>Os dados que você adicionou serão apagados</EscolaModalWarning>
                        <ModalButtonsContainer>
                            <ContinueEditing onClick={closeRemoveEscolaModal}>Voltar a editar</ContinueEditing>
                            <Button className={classes.removeEscolaButton} onClick={removeEscola}>Excluir</Button>
                        </ModalButtonsContainer>
                    </CardContent>
                </Card>
            </Modal>
        </Accordion>
    )
}

export default EscolaCard

interface EscolaCardProps extends CardProps {
    escola: Escola
    turmas: Turma[]
    disciplinas: Disciplina[]
    handleRemoveEscola: (escola: Escola) => void
    handleAddTurmaDisciplina: (turma: Turma, disciplina: Disciplina, escola: Escola) => void
    handleAddAllDisciplinas: (turma: Turma, disciplinas: Disciplina[], escola: Escola) => void
    handleAddAllTurmas: (disciplina: Disciplina, turmas: Turma[], escola: Escola) => void
    handleAddAllTurmasDisciplinas: (turmas: Turma[], disciplinas: Disciplina[], escola: Escola) => void
    handleRemoveTurmaDisciplina: (turmaDisciplinaToRemove: TurmaDisciplina, escola: Escola) => void
}
