import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Paper, CircularProgress, Fade } from "@material-ui/core";

import Input from "../../components/Input";
import Button from "../../components/Button";

import {
    useStyles,
    Container,
    RecoverPasswordForm,
    Title,
    Subtitle,
    SignUpRedirectContainer,
    Heading1,
    Heading2,
    BacktoLogin,
    ErrorMessage,
} from "./styles";

import axios from "axios";

import useIsMobile from "../../hooks/useIsMobile";

import { getEnvironment, protocol } from "../../utils";
import { Environment } from "../../global";

const RecoverPassword = () => {
    const history = useHistory();
    const classes = useStyles();

    const [state, setState] = useState({ username: '' });
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msgError, setMsgError] = useState("");

    const isMobile = useIsMobile()

    const { username } = state

    const submit = async () => {
        if (!processing) {
            setProcessing(true);

            const env: Environment = await getEnvironment(username);

            if (!env) {
                setMsgError("Username não encontrado");
            }

            axios
                .post(`${protocol}://${env.domain_url}/api/custom_password_reset/`, {
                    username,
                }, { timeout: 10000 })
                .then(() => setSuccess(true))
                .catch(({ response }) => {
                    let msg = "Houve um erro no servidor. Por favor, contate o administrador";

                    if (response && response.status === 400 && response.data && response.data.length)
                        msg = response.data[0]

                    setMsgError(msg);
                    setError(true);
                })
                .finally(() => setProcessing(false))
        }
    };

    const goToCadastro = () => history.push("/", { goToCadastro: true });

    useEffect(() => {
        setError(false)
    }, [state])

    return (
        <Container>
            <Grid container className={classes.container}>
                <Grid item md={6}>
                    <Paper className={classes.overlay} id="paper">
                        <Fade timeout={1000} in={true}>
                            <RecoverPasswordForm>
                                {!success ? (
                                    <>
                                        <Title>Esqueceu a senha?</Title>
                                        <Subtitle>
                                            Não tem problema, insira seu username abaixo para lhe
                                            enviarmos seu acesso ;)
                                        </Subtitle>
                                        <ErrorMessage visible={error}>{msgError}</ErrorMessage>
                                        <Input
                                            state={state}
                                            setState={setState}
                                            error={error}
                                            label="Username"
                                            placeholder="Digite seu username"
                                            name="username"
                                        ></Input>

                                        <Button disabled={!username} onClick={submit}>
                                            {processing ? (
                                                <CircularProgress
                                                    size={24}
                                                    style={{ color: "white" }}
                                                ></CircularProgress>
                                            ) : (
                                                "ENVIAR"
                                            )}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Title>Recebemos sua solicitação</Title>
                                        <Subtitle>
                                            Em breve você receberá um email contendo as suas
                                            informações de acesso
                                        </Subtitle>
                                    </>
                                )}

                                <BacktoLogin
                                    className={`${!success ? "text-align" : ""}`}
                                    href="/"
                                >
                                    Voltar ao Login
                                </BacktoLogin>
                            </RecoverPasswordForm>
                        </Fade>
                    </Paper>
                </Grid>
                {
                    !isMobile && (
                        <Grid item md={6} className={classes.rightSide}>
                            <SignUpRedirectContainer>
                                <Heading1>Não tem conta?</Heading1>
                                <Heading2>
                                    Faça seu cadastro para começar a usar o Gestor de Desempenho.
                                </Heading2>
                                <Button onClick={goToCadastro}>FAZER CADASTRO</Button>
                            </SignUpRedirectContainer>
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    );
};

export default RecoverPassword;
