import { useHistory } from "react-router";

import ModalForm from "../../components/ModalForm";

import { usePerfilContext } from "../Perfil/context";

import { Occupation } from "../../global"
import ProfessorRelationshipsForm from "./ProfessorRelationshipsForm";
import CoordenadorRelationshipsForm from "./CoordenadorRelationshipsForm";
import AssessorRelationshipsForm from "./AssessorRelationshipsForm";

const DadosAcompanhamentoUpdate = () => {
    const { profile } = usePerfilContext()
    const { goBack } = useHistory()

    const renderRelationshipForm = (role: number) => {
        switch (role) {
            case Occupation.PROFESSOR: return <ProfessorRelationshipsForm></ProfessorRelationshipsForm>;
            case Occupation.ASSESSOR: return <AssessorRelationshipsForm></AssessorRelationshipsForm>
            default: return <CoordenadorRelationshipsForm></CoordenadorRelationshipsForm>
        }
    }

    return (
        <ModalForm open={true} title="Editar dados de acompanhamento" onCloseModal={goBack}>
            {renderRelationshipForm(profile.occupation)}
        </ModalForm>
    )
}

export default DadosAcompanhamentoUpdate