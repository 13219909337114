/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useHistory } from "react-router"

import { parse, format } from "date-fns"

import { isEqual } from "lodash"

import Button from "../../components/Button"
import ModalForm from "../../components/ModalForm"
import Snackbar from "../../components/Snackbar"
import SuccessButton from "../../components/SuccessButton"
import Input from "../../components/Input"
import MaskedInput from "../../components/MaskedInput"
import DatePicker from "../../components/DatePicker"

import { useApi } from "../../context/innerContext"
import { usePerfilContext } from "../Perfil/context"

import useIsMobile from "../../hooks/useIsMobile"

import { ProfileUpdatableData } from "../../global"

import { phoneIsValid, unformatTelefone } from "../../utils"

import { MaskedInputContainer } from "../Perfil/styles"

const InformacoesBasicasUpdate = () => {
    const api = useApi()
    const isMobile = useIsMobile()
    const { goBack } = useHistory()
    const { updateInformacoesBasicas, profile } = usePerfilContext()

    type State = {
        nome: string
        cpf: string
        telefone_celular: string
        data_nascimento: Date | null
    }

    const [state, setState] = useState<State>({
        nome: '',
        cpf: '',
        telefone_celular: '',
        data_nascimento: null
    })

    const [telefoneError, setTelefoneError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)
    const [submitButtonIsDisabled, setSubmitButtonisDisabled] = useState(true)

    const { cpf, data_nascimento, nome, telefone_celular } = state

    useEffect(() => {
        setState({ ...state, nome: profile.nome })

        profile.cpf && setState({ ...state, cpf: profile.cpf })
        profile.telefone_celular && setState({ ...state, telefone_celular: profile.telefone_celular })
        profile.data_nascimento && setState({ ...state, data_nascimento: parse(profile.data_nascimento, 'dd/MM/yyyy', new Date()) })
    }, [])

    useEffect(() => {
        const formIsValid = !!nome && !!telefone_celular && !!data_nascimento

        let dataNascimentoInput;

        /**
         * Tratamento para formatação da data de nascimento, pois o método format não
         * espera receber data nullable
         */
        try {
            dataNascimentoInput = format(data_nascimento!, 'dd/MM/yyyy')
        } catch (error) {
            dataNascimentoInput = null
        }

        const inputValues = {
            nome, data_nascimento: dataNascimentoInput, telefone_celular: unformatTelefone(telefone_celular)
        }

        const { email, username, occupation, cpf, ...infosBasicas } = profile

        // Se os dados dos inputs estiverem diferentes dos dados retornado do perfil, habilita o botão 
        const dataHasChanged = !isEqual(inputValues, infosBasicas)
        setSubmitButtonisDisabled(!(dataHasChanged && formIsValid))
    }, [nome, cpf, telefone_celular, data_nascimento])

    const submit = () => {
        if (!phoneIsValid(telefone_celular)) {
            setTelefoneError(true)
            return
        }

        const personalInfo: ProfileUpdatableData = {};

        personalInfo.nome = nome;
        personalInfo.telefone_celular = unformatTelefone(telefone_celular)
        personalInfo.data_nascimento = format(data_nascimento!, "yyyy-MM-dd");

        setProcessing(true);

        api
            .changeUserData(personalInfo)
            .then(() => {
                const dataNascimentoBrFormatted = format(data_nascimento!, "dd/MM/yyyy")
                updateInformacoesBasicas({ ...profile, ...personalInfo, data_nascimento: dataNascimentoBrFormatted })

                setSuccess(true);

                setTimeout(() => goBack(), 2000)

                if (isMobile) setSnackbarIsOpen(true)
            })
            .catch(() => alert("Houve um erro ao atualizar o dado"))
            .finally(() => setProcessing(false));
    };

    return (
        <ModalForm open={true} title="Editar informações básicas" onCloseModal={goBack}>
            <Input
                label="Nome"
                placeholder="Insira o seu nome"
                state={state}
                setState={setState}
                name="nome"
            ></Input>
            <MaskedInputContainer>
                <MaskedInput
                    label="Telefone"
                    placeholder="(00) 00000-0000"
                    mask="(99) 99999-9999"
                    value={telefone_celular}
                    onChange={(e) => setState({ ...state, telefone_celular: e.target.value })}
                    error={telefoneError}
                    message="Insira um telefone válido"
                ></MaskedInput>
                <DatePicker
                    label="Data de Nascimento"
                    onChange={(date) => setState({ ...state, data_nascimento: date })}
                    value={data_nascimento}
                />
            </MaskedInputContainer>
            {!success ? (
                <Button
                    onClick={submit}
                    loading={processing}
                    disabled={submitButtonIsDisabled}
                >
                    Salvar
                </Button>
            ) : (
                <SuccessButton onClick={goBack}>
                    Alterações Salvas ;)
                </SuccessButton>
            )}
            <Snackbar
                open={snackbarIsOpen}
                onClose={() => setSnackbarIsOpen(false)}
                message="Alterações salvas ;)"
            ></Snackbar>
        </ModalForm>
    )
}

export default InformacoesBasicasUpdate