import { createActions, createReducer } from 'reduxsauce';
import { Environment } from '../../global';

// interfaces
interface AuthActions {
    SET_TOKEN: string;
    SET_ENVIRONMENT: string;
    LOGOUT: string;
}

interface AuthCreators {
    setToken: (token: string) => any;
    setEnvironment: (env: Environment) => any;
    logout: () => any;
}

export interface AuthStore {
    token: string;
    environment?: Environment;
}

// initial state
const INITIAL_STATE: AuthStore = ({
    token: ''
});

// reducers
const setToken = (state = INITIAL_STATE, action: any) => ({ ...state, token: action.token });
const setEnvironment = (state = INITIAL_STATE, action: any) => ({ ...state, environment: action.environment });
const logout = () => INITIAL_STATE

// actions
export const { Types: AuthTypes, Creators: AuthCreators } = createActions<AuthActions, AuthCreators>({ /* eslint-disable-line */
    setToken: ['token'],
    setEnvironment: ['environment'],
    logout: []
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
    [AuthTypes.SET_TOKEN]: setToken,
    [AuthTypes.SET_ENVIRONMENT]: setEnvironment,
    [AuthTypes.LOGOUT]: logout
});
