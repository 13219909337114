import { Stepper, StepperProps, StepConnector, withStyles } from '@material-ui/core'

import useStyles from './styles'

const Connector = withStyles({
    line: {
        borderColor: '#A5D9FF',
        borderWidth: 2
    }
})(StepConnector)

const CustomStepper = (props: StepperProps) => {
    const classes = useStyles()

    return (
        <Stepper className={classes.root} {...props} connector={<Connector></Connector>}></Stepper>
    )
}

export default CustomStepper