import { useEffect, useState } from "react"
import { useHistory } from "react-router"

import Button from "../../components/Button"
import ModalForm from "../../components/ModalForm"
import PasswordInput from "../../components/PasswordInput"
import Snackbar from "../../components/Snackbar"
import SuccessButton from "../../components/SuccessButton"

import useIsMobile from "../../hooks/useIsMobile"

import { useApi } from "../../context/innerContext"

const UserPasswordUpdate = () => {
    const api = useApi()
    const isMobile = useIsMobile()
    const { goBack } = useHistory()

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordConfirmationError, setPasswordConfirmationError] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState(false);
    const [success, setSuccess] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)

    useEffect(() => {
        passwordConfirmationError && setPasswordConfirmationError(false);
        oldPasswordError && setOldPasswordError(false);
    }, [oldPassword, newPassword, passwordConfirmation]); /* eslint-disable-line */

    const handleSubmitPassword = () => {
        if (newPassword !== passwordConfirmation) {
            setPasswordConfirmationError(true);
            return;
        }

        setProcessing(true);

        api
            .changePassword(newPassword, oldPassword)
            .then(() => {
                setSuccess(true);
                setTimeout(() => goBack(), 2000)

                if (isMobile) setSnackbarIsOpen(true)
            })
            .catch(({ response }) => {
                if (response.data && response.data.old_password)
                    setOldPasswordError(true);
            })
            .finally(() => setProcessing(false));
    };

    return (
        <ModalForm
            open={true}
            title="Mudar senha"
            onCloseModal={goBack}
        >
            <PasswordInput
                label="Insira abaixo a senha atual"
                placeholder="Senha atual"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                message="Senha atual incorreta"
                error={oldPasswordError}
            ></PasswordInput>
            <PasswordInput
                label="Insira sua nova senha"
                placeholder="Digite sua nova senha"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
            ></PasswordInput>
            <PasswordInput
                label="Confirme sua nova senha"
                placeholder="Digite novamente"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                message="A confirmação de senha não corresponde"
                error={passwordConfirmationError}
            ></PasswordInput>
            {!success ? (
                <Button
                    onClick={handleSubmitPassword}
                    loading={processing}
                    disabled={!(oldPassword && newPassword && passwordConfirmation)}
                >
                    Salvar
                </Button>
            ) : (
                <SuccessButton onClick={goBack}>
                    Alterações Salvas ;)
                </SuccessButton>
            )}
            <Snackbar
                open={snackbarIsOpen}
                onClose={() => setSnackbarIsOpen(false)}
                message="Alterações salvas ;)"
            ></Snackbar>
        </ModalForm>
    )
}

export default UserPasswordUpdate