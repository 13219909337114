/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux'

import { useMediaQuery } from '@material-ui/core';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import { isEqual } from 'lodash';

import { Escola, Occupation } from "../../global";

import { Store } from "../../store";
import { useApi } from '../../context/innerContext';

import { Container, Embedded, Message, MessageContainer, Warning } from "./styles";
import { REACT_APP_STAGING_URL } from '../../env';

const Home = () => {
  const isMobile = useMediaQuery("(max-width: 959px)");

  const api = useApi()

  const [accessToken, setAccessToken] = useState('')
  const [reportFilters, setReportFilters] = useState<string[]>([])
  const [professorPrimario, setProfessorPrimario] = useState(false)

  const env = useSelector((state: Store) => state.auth.environment)
  const token = useSelector((state: Store) => state.auth.token)
  const occupation = useSelector((state: Store) => state.profile.occupation)

  const filterParams = useSelector((state: Store) => {
    return {
      table: "Dimensao_Escola",
      column: "Nome Escola"
    }
  });

  // const report_id = useSelector((state: Store) => {
  //   switch (state.profile.occupation) {
  //     case Occupation.occ:
  //     // return 'd4e78724-f592-4b40-ab79-abe1cde9fff4'
  //     case Occupation.COORDENADOR:
  //     case Occupation.ASSESSOR:
  //       return 'ee01e2e3-6078-4e89-81fd-9dc556ea7acb'
  //     default:
  //       return '';
  //   }
  // })

  const reports = {
    semed: 'ee01e2e3-6078-4e89-81fd-9dc556ea7acb',
    educar: '47275818-217d-45b5-a3c9-6c6b8eb5862d',
    staging: 'bda63e98-d9b1-436c-a6b9-4a5ea475eb32'
  }

  const reportId = useMemo(() => {
    if (!env) return ''

    if (REACT_APP_STAGING_URL) return reports.staging

    const workspace = env.name.toLowerCase() as 'semed' | 'educar' | 'staging'

    return reports[workspace]
  }, [env])

  const getPbiToken = () => {
    api.getPowerBiAccessToken().then(({ data }) => setAccessToken(data.access_token))
  }

  useEffect(() => {
    setInterval(getPbiToken, 20 * 60 * 1000)
  }, [])

  const professorTeachesPrimario = (relationships: Escola[]) => {
    const seriesPrimario = [1, 2, 3, 4]

    const seriesTurmas: number[] = []

    relationships.forEach(({ turmas_disciplinas }) => turmas_disciplinas?.forEach(({ turma: { serie } }) => seriesTurmas.push(serie!)))

    return seriesTurmas.every((serie) => seriesPrimario.includes(serie))
  }

  useEffect(() => {
    if (env && token && typeof occupation != 'undefined') {
      const convertEscolasToString = (escolas: Escola[]) => {
        let escolasString: string[] = []

        escolas.forEach(({ nome }: Escola) => escolasString.push(nome))

        return escolasString
      }

      if (occupation === Occupation.PROFESSOR) {
        api.getProfessorRelationships()
          .then(({ data }) => {
            const professorPrimario = professorTeachesPrimario(data)
            setProfessorPrimario(professorPrimario)
          })
          .catch(() => alert('Houve um erro ao buscar as turmas do professor.'))

        if (!professorPrimario)
          api.getProfessorEscolas()
            .then(({ data }) => {
              const escolas = convertEscolasToString(data)
              setReportFilters(escolas)
            })
      }
      else
        api.getCoordenadorRelationships()
          .then(({ data }) => {
            const escolas = convertEscolasToString(data)
            setReportFilters(escolas)
          })

      if (!professorPrimario) getPbiToken()
    }
  }, [env, token, occupation, professorPrimario])

  const filters = [{
    $schema: "http://powerbi.com/product/schema#basic",
    filterType: 1,
    target: filterParams,
    operator: "In",
    values: reportFilters
  }]

  /**
   * Ao entrar na página 'Conteudo Todos os Alunos' volta os filtros para o estado inicial
   * @param report 
   */
  async function resetFilters(report: any) {
    const pages = await report.getPages()

    const activePage = pages.find((page: any) => page.isActive)

    if (activePage.displayName === "Cockpit") {
      const notDesiredFilter = {
        "$schema": "http://powerbi.com/product/schema#basic",
        "target": {
          "table": "Dimensao_Escola",
          "column": "Nome Escola"
        },
        "filterType": 1,
        "operator": "All",
        "values": [],
        "requireSingleSelection": false
      }

      const reportFilters = await report.getFilters()

      const [serieFilter, allEscolasFilter] = reportFilters

      if (isEqual(allEscolasFilter, notDesiredFilter)) {
        await report.updateFilters(models.FiltersOperations.ReplaceAll, [serieFilter, ...filters]);
      }
    }
  }

  const embedded = useMemo(() => {
    if (reportFilters.length && filterParams && accessToken && reportId)
      return (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: reportId,
            embedUrl: 'https://app.powerbi.com/reportEmbed',
            accessToken,
            tokenType: models.TokenType.Aad,
            // @ts-ignore
            filters
          }}
          cssClassName={"Embed-container"}
          eventHandlers={
            new Map([
              ['rendered', function (e: any) { resetFilters(e.srcElement.powerBiEmbed) }]
            ])
          }
        />
      )

    return null
  }, [reportFilters.length, filterParams, accessToken, reportId])

  return (
    <Container>
      {isMobile ? (
        <Warning>
          Os dados de acompanhamento estão disponíveis somente para acessar pelo
          computador.
          <p>Pelo celular você pode editar seus dados de cadastro!</p>
        </Warning>
      ) : professorPrimario ? (
        <MessageContainer>
          <Message>
            Os relatórios do Fundamental I (1º a 4º) ano podem ser acessados <a href="https://acesso.superensino.com.br/" target='_blank' rel="noreferrer">aqui</a>.
            {/* Os relatórios do Fundamental II (5º ao 9º ano) estarão disponíveis em breve. Os relatórios do Fundamental I (1º a 4º) ano podem ser acessados <a href="https://acesso.superensino.com.br/" target='_blank'>aqui</a>. */}
          </Message>
        </MessageContainer>
      )
        : (
          <Embedded>
            {embedded}
          </Embedded>
        )
      }
    </Container>
  );
};
export default Home;
