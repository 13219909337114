import { ButtonHTMLAttributes } from 'react'
import { CircularProgress } from '@material-ui/core'

import { StyledButton } from './styles'

const Button = ({ loading, children, onClick, ...rest }: ButtonProps) => (
    <StyledButton {...rest} onClick={loading ? undefined : onClick}>
        {loading ? <CircularProgress
            size={24}
            style={{ color: "white" }}
        ></CircularProgress> : children}
    </StyledButton>
)

export default Button

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean
}