import styled from "styled-components";

export default styled.button`
    height: 51px;
    font-size: 18px;
    cursor: pointer;
    text-decoration-line: underline;
    color: #0295FF;
    font-weight: 400;
    border: 0;
    font-family: Ubuntu, sans-serif;
    background: transparent;
    border-radius: 12px;

    &:active, &:hover {
        background: #D9EFFF;
        transition: background .25s ease;
    }
`