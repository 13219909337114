import styled, { css } from 'styled-components'
import { createStyles, makeStyles, Link } from '@material-ui/core'

import Button from "../Button";
import UnderlinedTextButton from '../UnderlinedTextButton';

import ImgLogo from '../../assets/logo.png'
import youtubeIcon from '../../assets/icons/youtube.svg'
import facebookIcon from '../../assets/icons/facebook.svg'
import instagramIcon from '../../assets/icons/instagram.svg'
import linkedinIcon from '../../assets/icons/linkedin.svg'
import meninaIcon from '../../assets/icons/menina.svg'
import logoMobile from '../../assets/icons/logoMobile.svg'
import sair from "../../assets/icons/sair.svg";
import superEnsinoGamesIcon from "../../assets/icons/super-ensino-games.png";

export const useStyles = makeStyles(() => createStyles({
  drawerPaper: {
    width: 80,
    background: '#FAFDFF',
    boxShadow: '0px 4px 8px rgba(1, 128, 219, 0.15)',
    position: 'initial',
    border: 0
  },
  modalCard: {
    boxShadow: '2px 8px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: 20,
    width: 236,
    height: 132,
    paddingTop: 28
  },
  modalCardHeader: {
    padding: 0,
  },
  modalCardContent: {
    paddingTop: 0,
    paddingRight: 36,
    paddingLeft: 36,
  },
  drawerPaperMobile: {
    width: '100%',
    alignItems: 'center'
  },
  drawerContentMobile: {
    marginTop: 64,
    alignItems: 'flex-start'
  },
  listMobile: {
    padding: 0,
    width: '100%'
  },
  listItemMobile: {
    paddingTop: 0,
    paddingBottom: 22,
    paddingLeft: 23
  },
  ListItemIconMobile: {
    minWidth: 32
  },
  listItemText: {
    color: 'black',
  },
  svgIcon: {
    marginLeft: 16,
    fill: 'none',
  },
  menuIcon: {
    cursor: 'pointer',
    marginTop: 4,
  },
  modalMobile: {
    display: 'flex',
    justifyContent: 'center'
  },
  positionAbsolute: {
    position: 'absolute',
    top: 317,
    left: 103
  }
}))

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 48px 0;
  width: 80px;
  background: #FAFDFF;
  boxShadow: 0px 4px 8px rgba(1, 128, 219, 0.15);
`

export const Container2 = styled.div`
  max-height: 631px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  margin-top: 48px;
`

export const Logo = styled.img.attrs(() => ({ src: ImgLogo, alt: "Logo" }))`margin-bottom: 27px;`;

export const LogoMobile = styled.img.attrs(() => ({ src: logoMobile, alt: "Logo" }))`
  position: absolute;
  margin-left: 12vw;
`;

export const MenuItems = styled.div`
  display: grid;
  row-gap: 18px;
  justify-content: center;
  text-align: center;
  padding: 16px 0px;

  width: 62px;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  background: #F8F9FE;
  box-shadow: inset 2px 2px 1px rgba(1, 128, 219, 0.1);
  border-radius: 15px;

  margin-bottom: 68px;
`

export const Item = styled.a.attrs((props: { isActive: boolean }) => ({
  isActive: props.isActive
}))`
  width: 48px;
  height: 48px;
  padding: 4px;

  border: 0;
  background: none;
  color: #6B6878;
  transition: all .5s;
  
  svg path {
    stroke: #6B6878;
    fill: none;
  }

  :hover {
    color: #0295FF;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(1, 128, 219, 0.2);
    border-radius: 12px;
    stroke: #0295FF;
    cursor: pointer;

    svg path {
      stroke: #0295FF;
    }
  }

  ${props => props.isActive && css`
    color: #0295FF;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(1, 128, 219, 0.2);
    border-radius: 12px;
    stroke: #0295FF;
    cursor: pointer;
    transform: translateY(-2px);

    svg path {
      stroke: #0295FF;
    }
  `}
`

export const SocialMedias = styled(Link).attrs(() => ({
  target: "_blank"
}))`
  width: 30px;
  height: 30px;

  border: none;
  border-radius: 50%;
  transition: all .5s;

  :hover {
    transform: translateY(-6px);
  }

  @media (max-width: 960px) {
    width: 24px;
    height: 24px;
  }
`

export const Youtube = styled(SocialMedias).attrs(() => ({
  href: "https://www.youtube.com/channel/UC2cU1M0CZKu14knTYc_pp7g",
}))`
  background: url(${youtubeIcon}) no-repeat center center,
    #FF0000;

  @media (max-width: 960px) {
    background-size: 16px 10px;
  }
`

export const Instagram = styled(SocialMedias).attrs(() => ({
  href: "https://www.instagram.com/superensino/?hl=pt-br",
}))`
  background: url(${instagramIcon}) no-repeat center center,
    #8C00E2;

  @media (max-width: 960px) {
    background-size: 14px;
  }
`

export const Facebook = styled(SocialMedias).attrs(() => ({
  href: "https://www.facebook.com/go.superensino",
}))`
  background: url(${facebookIcon}) no-repeat center center,
    #1877F2;

  @media (max-width: 960px) {
    background-size: 10px 19px;
    background-position: center bottom;
  }
`

export const LinkedIn = styled(SocialMedias).attrs(() => ({
  href: "https://www.linkedin.com/company/super-ensino/mycompany/",
}))`
  background: url(${linkedinIcon}) no-repeat center center,
    #0077B5;

  @media (max-width: 960px) {
    background-size: 14px;
  }
`

export const AcessoSuperAluno = styled(SocialMedias).attrs(() => ({
  href: "https://aluno.superensino.com.br/",
}))`
  background: url(${meninaIcon}) no-repeat center center;

@media (max-width: 960px) {
    background-size: 24px 24px;
  }
`

export const Sair = styled(SocialMedias)`
  background: url(${sair}) no-repeat center center;

@media (max-width: 960px) {
    background-size: 24px 24px;
  }
`

export const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding-left: 22.5px;
    padding-right: 22.5px;
    cursor: pointer;
`

export const ModalButton1 = styled(UnderlinedTextButton)`
    font-size: 16px;
    font-weight: normal;
    padding: 0 15px;
    margin-right: 5px;
`

export const ModalButton2 = styled(Button)`
  width: 81px;
  height: 48px;
`

export const CardAcesso = styled.div`
    padding: 16px 24px;

    position: absolute;
    left: 96px;
    top: 290px;

    background: white;
    box-shadow: 2px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 20px;

    display: grid;
    grid-template-columns: max-content 1fr;
`

export const ModalAcessoSuperAlunoIcon = styled.img.attrs(() => ({
  src: meninaIcon
}))``

export const ModalAcessoSuperEnsinoGamesIcon = styled.img.attrs(() => ({
  src: superEnsinoGamesIcon
}))`
  width: 85px;
`

export const GotoSuperAluno = styled(UnderlinedTextButton).attrs(() => ({
  as: "a",
  href: "https://aluno.superensino.com.br/",
  target: "_blank"
}))`
    font-size: 16px;
    font-weight: normal;
    padding: 12px;
    margin-left: 3px;
    height: auto;
`

export const GotoSuperEnsinoGames = styled(UnderlinedTextButton).attrs(() => ({
  as: "a",
  href: "https://acesso.superensino.com.br/",
  target: "_blank"
}))`
    font-size: 16px;
    font-weight: normal;
    padding: 12px;
    margin-left: 3px;
    height: auto;
`

export const MenuMobile = styled.div`
  width: 100%;
  height: 56px;

  padding: 23px;
  background: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ItemsMobile = styled.div`
  display: flex;
  justify-content: space-between;

  color: #0295FF;
  cursor: pointer;
`

export const ItemMobile = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const SocialMediaContainer = styled.div`
  display: grid;
  row-gap: 18px;  
`