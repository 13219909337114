import styled from "styled-components";
import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
    root: {
        border: '1px solid #8F8E94',
        borderRadius: 8,
        padding: '0 12px',
        height: 48,
        margin: '8px 0',
        justifyContent: 'center',
        width: '100%'
    },
    errorState: {
        borderColor: '#FC4821',
        borderWidth: 2
    },
    focusedState: {
        borderColor: '#0295FF',
        borderWidth: 2
    }
}))

export const Container = styled.div`
    width: 100%;
    text-align: left;
`

export const Label = styled.label`
    font-size: 14px;
    color: #373748;
`

export const ErrorMessage = styled.p<{ visible?: boolean }>`
    color: #FC4821;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    font-size: 12px;
`