import { InputHTMLAttributes, useState, ReactNode, useRef } from "react";

import Tooltip from "../../../../components/Tooltip";

import {
    Container,
    StyledInput,
    Label,
    ErrorMessage,
    InputWrapper,
    ShimmerPlaceholder
} from "./styles";

import info from '../../../../assets/info.svg'

const UsernameReadonlyInput = ({
    state,
    setState,
    error,
    loading,
    onBlur,
    ...inputProps
}: InputArgs) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

    const infoIcon = (
        <Tooltip title="Você deve utilizar o username para logar" placement="top" open={tooltipIsOpen}>
            <img src={info} onMouseOver={() => setTooltipIsOpen(true)} onMouseOut={() => setTooltipIsOpen(false)} alt="tip"></img>
        </Tooltip>
    )

    return (
        <Container>
            <Label>Username</Label>
            <InputWrapper error={error}>
                {
                    loading ? <ShimmerPlaceholder></ShimmerPlaceholder> :
                        <StyledInput
                            ref={ref}
                            onFocus={e => { e.target.readOnly = false }}
                            onBlur={(e) => {
                                onBlur && onBlur(e)
                            }}
                            onChange={e => { setState({ ...state, username: e.target.value }) }}
                            readOnly
                            placeholder="O seu username será gerado automaticamente"
                            value={state.username}
                        ></StyledInput>
                }
                {infoIcon}
            </InputWrapper>
            <ErrorMessage visible={error}>Aguarde a geração do username</ErrorMessage>
        </Container>
    )
}

export default UsernameReadonlyInput;

type ReactInput = InputHTMLAttributes<HTMLInputElement>;
type InputArgs = InputProps & Omit<ReactInput, keyof InputProps>

interface InputProps {
    error?: boolean;
    state: any;
    setState: any;
    message?: string;
    label?: string
    endAdornment?: ReactNode
    loading?: boolean
    uppercase?: boolean
}
