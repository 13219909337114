import { useContext } from 'react'

import { CadastroContext, CadastroContextType } from '../../context/cadastroContext'

import { Container, Title, ContentContainer, GreenBorderContainer, Subtitle, Check, LoginIs, Login } from './styles'


const FinishedRegister = () => {
    const { state } = useContext(CadastroContext) as CadastroContextType

    return (
        <Container>
            <ContentContainer>
                <Check />
                <Title>Cadastro finalizado</Title>
                <Subtitle>Seu cadastro foi enviado! Assim que aprovado você receberá um email e você já poderá utilizar os produtos da Super Ensino.</Subtitle>
                <GreenBorderContainer>
                    <LoginIs>Seu login é:</LoginIs>
                    <Login>{state.username}</Login>
                </GreenBorderContainer>
            </ContentContainer>
        </Container>
    )
}

export default FinishedRegister