import { createContext, FC, useState } from "react";
import { isEqual } from 'lodash'

import { CadastroState, Disciplina, Distrito, Environment, Escola, Funcao, SelectedDistrito, Turma, TurmaDisciplina } from "../global";
import { arrayContainsObject, updateObjectsArray } from "../utils";
import Api from '../services'

export const CadastroContext = createContext<CadastroContextType | null>(null)

const CadastroProvider: FC = ({ children }) => {
    const [state, setState] = useState<CadastroState>({
        step: 0,
        finishedRegister: false,
        funcao: undefined,
        environment: undefined,
        selectedEscolas: [],
        selectedDistritos: [],
        distritos: [],
        username: '',
        email: '',
        password: '',
        confirmation: '', // Confirmação da senha
        nome: '',
        cpf: '',
        telefone: '',
        data_nascimento: null
    })

    const api = new Api(state.environment?.domain_url!)

    const handleChangeUsername = (username: string) => setState({ ...state, username })

    const handleSetDistritos = (distritos: Distrito[]) => setState({ ...state, distritos })

    const handleChangeFuncao = (funcao: Funcao) => setState({ ...state, funcao, selectedEscolas: [], selectedDistritos: [] })

    const handleAddEscola = (newEscola: Escola) => {
        const escolaIsNotInSelectedEscolas = !state.selectedEscolas.find((escola) => escola.id === newEscola.id)

        if (escolaIsNotInSelectedEscolas) {
            const selectedEscolas = [{ ...newEscola, turmas_disciplinas: [] }, ...state.selectedEscolas]
            setState({ ...state, selectedEscolas })
        }
        // if (escolaIsNotInSelectedEscolas) setSelectedEscolas([{ ...newEscola, turmas_disciplinas: [] }, ...selectedEscolas])
        else alert('Esta escola já foi adicionada')
    }

    const handleRemoveEscola = (escolaToRemove: Escola) => {
        const selectedEscolas = state.selectedEscolas.filter((escola) => escolaToRemove.id !== escola.id)
        setState({ ...state, selectedEscolas })
    }
    // const handleRemoveEscola = (escolaToRemove: Escola) => setSelectedEscolas(selectedEscolas.filter((escola) => escolaToRemove.id !== escola.id))

    const handleAddTurmaDisciplina = (turma: Turma, disciplina: Disciplina, oldEscola: Escola) => {
        const turmaDisciplinaToAdd: TurmaDisciplina = { turma, disciplina }

        if (arrayContainsObject(oldEscola.turmas_disciplinas!, turmaDisciplinaToAdd)) {
            alert('Esta turma e disciplina já foram adicionadas')
            return
        }

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            turmaDisciplinaToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        // Passagem de cópias dos arrays com o spread operator para evitar efeitos colaterais ao passar a referência em si do array que representa o state das escolas
        const selectedEscolas = updateObjectsArray([...state.selectedEscolas], oldEscola, newEscola)

        setState({ ...state, selectedEscolas })
    }

    const handleRemoveTurmaDisciplina = (turmaDisciplinaToRemove: TurmaDisciplina, oldEscola: Escola) => {
        const oldSelectedTurmasDisciplinas = oldEscola.turmas_disciplinas!
        const selectedTurmasDisciplinas = oldSelectedTurmasDisciplinas.filter((turmaDisciplina) => !isEqual(turmaDisciplina, turmaDisciplinaToRemove))

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        // Passagem de cópias dos arrays com o spread operator para evitar efeitos colaterais ao passar a referência em si do array que representa o state das escolas
        const selectedEscolas = updateObjectsArray([...state.selectedEscolas], oldEscola, newEscola)

        setState({ ...state, selectedEscolas })
    }

    const handleAddDistrito = (newDistrito: Distrito) => {
        const distritoIsNotInSelectedDistritos = !state.selectedDistritos.filter(({ distrito }) => isEqual(newDistrito, distrito)).length

        if (distritoIsNotInSelectedDistritos) {
            const selectedDistritos = [{ distrito: newDistrito, selectedEscolas: newDistrito.escolas }, ...state.selectedDistritos]
            setState({ ...state, selectedDistritos })
        }
        else alert('Este distrito já foi adicionado')
    }

    const handleRemoveDistrito = (distritoToRemove: SelectedDistrito) => {
        const selectedDistritos = state.selectedDistritos.filter((distrito) => !isEqual(distritoToRemove, distrito))
        setState({ ...state, selectedDistritos })
    }

    const handleAddEscolaDistrito = (escola: Escola, oldDistrito: SelectedDistrito) => {
        const selectedEscolas: Escola[] = [
            ...oldDistrito.selectedEscolas,
            escola
        ]

        const newDistrito: SelectedDistrito = {
            ...oldDistrito,
            selectedEscolas
        }

        const selectedDistritos = updateObjectsArray([...state.selectedDistritos], oldDistrito, newDistrito)

        setState({ ...state, selectedDistritos })
    }
    const handleRemoveEscolaDistrito = (escolaToRemove: Escola, oldDistrito: SelectedDistrito) => {
        const oldSelectedEscolas = oldDistrito.selectedEscolas
        const selectedEscolas = oldSelectedEscolas.filter((escola) => !isEqual(escola, escolaToRemove))

        const newDistrito: SelectedDistrito = {
            ...oldDistrito,
            selectedEscolas
        }

        const selectedDistritos = updateObjectsArray([...state.selectedDistritos], oldDistrito, newDistrito)

        setState({ ...state, selectedDistritos })
    }

    const handleAddAllEscolasDistrito = (selectedDistrito: SelectedDistrito) => {
        const newDistrito: SelectedDistrito = {
            ...selectedDistrito,
            selectedEscolas: selectedDistrito.distrito.escolas
        }

        const selectedDistritos = updateObjectsArray([...state.selectedDistritos], selectedDistrito, newDistrito)

        setState({ ...state, selectedDistritos })
    }
    const handleRemoveAllEscolasDistrito = (selectedDistrito: SelectedDistrito) => {
        const newDistrito: SelectedDistrito = {
            ...selectedDistrito,
            selectedEscolas: []
        }

        const selectedDistritos = updateObjectsArray([...state.selectedDistritos], selectedDistrito, newDistrito)

        setState({ ...state, selectedDistritos })
    }

    const handleChangeEnvironment = (_: any, environment: Environment) => setState({ ...state, environment })

    const allEscolasAreSelected = ({ distrito, selectedEscolas }: SelectedDistrito) => distrito.escolas.length === selectedEscolas.length

    const handleAddAllDisciplinas = (turma: Turma, disciplinas: Disciplina[], oldEscola: Escola) => {
        // Filtra as turmas-disciplinas que não estão nas turmas-disciplinas da escola e as retorna, assim evitando duplicatas
        const turmasDisciplinasToAdd = disciplinas
            .filter((disciplina) => !arrayContainsObject(oldEscola.turmas_disciplinas!, { turma, disciplina }))
            .map((disciplina) => ({ turma, disciplina }))

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            ...turmasDisciplinasToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        const selectedEscolas = updateObjectsArray([...state.selectedEscolas], oldEscola, newEscola)

        setState({ ...state, selectedEscolas })
    }

    const handleAddAllTurmas = (disciplina: Disciplina, turmas: Turma[], oldEscola: Escola) => {
        // Filtra as turmas-disciplinas que não estão nas turmas-disciplinas da escola e as retorna, assim evitando duplicatas
        const turmasDisciplinasToAdd = turmas
            .filter((turma) => !arrayContainsObject(oldEscola.turmas_disciplinas!, { turma, disciplina }))
            .map((turma) => ({ turma, disciplina }))

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            ...turmasDisciplinasToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        const selectedEscolas = updateObjectsArray([...state.selectedEscolas], oldEscola, newEscola)

        setState({ ...state, selectedEscolas })
    }

    const handleAddAllTurmasDisciplinas = (turmas: Turma[], disciplinas: Disciplina[], oldEscola: Escola) => {
        const turmasDisciplinasToAdd = []

        for (let i = 0; i < turmas.length; i++)
            for (let j = 0; j < disciplinas.length; j++) {
                const turmaDisciplinaToAdd = {
                    turma: turmas[i],
                    disciplina: disciplinas[j]
                }

                if (!arrayContainsObject(oldEscola.turmas_disciplinas!, turmaDisciplinaToAdd))
                    turmasDisciplinasToAdd.push(turmaDisciplinaToAdd)
            }

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            ...turmasDisciplinasToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        const selectedEscolas = updateObjectsArray([...state.selectedEscolas], oldEscola, newEscola)

        setState({ ...state, selectedEscolas })
    }

    const handleAddDistritos = (distritos: Distrito[]) => {
        const selectedDistritosIds: number[] = []

        state.selectedDistritos.forEach(({ distrito }) => selectedDistritosIds.push(distrito.id))

        const filterDistritosNotInSelectedDistritos = (distrito: Distrito) => !selectedDistritosIds.includes(distrito.id)

        const newSelectedDistritos = distritos.filter(filterDistritosNotInSelectedDistritos)
            .map((distrito) => ({ distrito, selectedEscolas: distrito.escolas }))

        setState({ ...state, selectedDistritos: [...state.selectedDistritos, ...newSelectedDistritos] })
    }

    return (
        <CadastroContext.Provider value={{ setState, handleAddDistritos, handleAddAllTurmasDisciplinas, handleAddAllDisciplinas, handleAddAllTurmas, handleChangeUsername, handleSetDistritos, api, allEscolasAreSelected, handleChangeEnvironment, handleAddEscola, handleRemoveEscola, handleAddTurmaDisciplina, handleRemoveTurmaDisciplina, handleAddDistrito, handleRemoveDistrito, handleAddEscolaDistrito, handleRemoveEscolaDistrito, handleAddAllEscolasDistrito, handleRemoveAllEscolasDistrito, handleChangeFuncao, state }}>
            {children}
        </CadastroContext.Provider>
    )
}

export default CadastroProvider

export interface CadastroContextType {
    state: CadastroState
    setState: (state: CadastroState) => void
    // selectedEscolas: Escola[]
    // selectedDistritos: SelectedDistrito[]
    // funcao?: Funcao
    // environment?: Environment
    api: Api
    handleAddEscola: (escola: Escola) => void
    handleRemoveEscola: (escola: Escola) => void
    handleAddTurmaDisciplina: (turma: Turma, disciplina: Disciplina, escola: Escola) => void
    handleRemoveTurmaDisciplina: (turmaDisciplina: TurmaDisciplina, escola: Escola) => void
    handleAddDistrito: (newDistrito: Distrito) => void
    handleRemoveDistrito: (distritoToRemove: SelectedDistrito) => void
    handleAddEscolaDistrito: (escola: Escola, oldDistrito: SelectedDistrito) => void
    handleRemoveEscolaDistrito: (escolaToRemove: Escola, oldDistrito: SelectedDistrito) => void
    handleAddAllEscolasDistrito: (selectedDistrito: SelectedDistrito) => void
    handleRemoveAllEscolasDistrito: (selectedDistrito: SelectedDistrito) => void
    handleChangeEnvironment: (_: any, environment: Environment) => void
    allEscolasAreSelected: (selectedDistrito: SelectedDistrito) => boolean
    handleChangeFuncao: (funcao: Funcao) => void
    // distritos: Distrito[]
    handleSetDistritos: (distritos: Distrito[]) => void
    // finishedRegister: boolean
    // setFinishedRegister: (finishedRegister: boolean) => void
    // username: string
    handleChangeUsername: (username: string) => void
    // step: number
    // setStep: (step: number) => void
    handleAddAllDisciplinas: (turma: Turma, disciplinas: Disciplina[], escola: Escola) => void
    handleAddAllTurmas: (disciplina: Disciplina, turmas: Turma[], escola: Escola) => void
    handleAddAllTurmasDisciplinas: (turmas: Turma[], disciplinas: Disciplina[], escola: Escola) => void
    handleAddDistritos: (distritos: Distrito[]) => void
}