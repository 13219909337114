import { useState } from 'react';
import 'date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx'

import useStyles, {
  Container,
  Label,
  ErrorMessage
} from "./styles";

const DatePicker = ({
  label,
  error,
  message,
  ...datePickerProps
}: DatePickerProps) => {
  const classes = useStyles()

  const [focused, setFocused] = useState(false)

  return (
    <Container>
      <Label>{label}</Label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          format="dd/MM/yyyy"
          {...datePickerProps}
          className={clsx(classes.root, error && classes.errorState, focused && classes.focusedState)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder="dd/mm/aaaa"
        />
      </MuiPickersUtilsProvider>
      <ErrorMessage visible={error}>{message}</ErrorMessage>
    </Container>
  )
}

export default DatePicker;

interface DatePickerProps extends KeyboardDatePickerProps {
  error?: boolean;
  message?: string;
  label: string
}