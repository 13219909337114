import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const CustomTooltip = (props: TooltipProps) => {
    const classes = useStyles();

    return (
        <Tooltip classes={{ tooltip: classes.tooltip }} {...props}>
            {props.children}
        </Tooltip>
    );
};

export default CustomTooltip

const useStyles = makeStyles(() => ({
    tooltip: {
        borderRadius: 5,
        backgroundColor: '#0180DB',
        fontSize: 13,
        fontWeight: 'bold',
        color: '#F7FCFE',
    },
}));
