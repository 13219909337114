import { createStyles, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles(() => createStyles(({
    snackbar: {
        padding: "16px 56px 16px 24px",
        width: 307,
        height: 59,
        backgroundColor: "#00E3BE",
        boxShadow: "0px 4px 8px rgba(30, 34, 125, 0.2)",
        borderRadius: 8,
    }
})))

export const SnackbarContainer = styled.div`
.MuiSnackbarContent-root {
    background-color: transparent;
    font-size: 18px;
    justify-content: center;
  }
  .MuiPaper-elevation6 {
    box-shadow: none;
  }

  @media (max-width: 959px) {
    .MuiSnackbar-anchorOriginBottomCenter {
      left: 50%;
      right: auto;
      bottom: 24px;
      transform: translateX(-50%);
    }
  }
`