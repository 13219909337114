import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Autocomplete from "../../../components/Autocomplete";
import Button from "../../../components/Button";
import Snackbar from "../../../components/Snackbar";
import SuccessButton from "../../../components/SuccessButton";
import EscolaCard from "./EscolaCard";
import Scrollbar from "../../../components/Scrollbar";

import { useApi } from "../../../context/innerContext";
import { usePerfilContext } from "../../Perfil/context";

import useIsMobile from "../../../hooks/useIsMobile";

import { Escola } from "../../../global"

import { useStyles } from "../styles";

const CoordenadorRelationshipsForm = () => {
    const classes = useStyles()
    const { goBack } = useHistory()
    const { relationships, updateRelationships } = usePerfilContext()
    const isMobile = useIsMobile()

    const [escolas, setEscolas] = useState<Escola[]>([])
    const [selectedEscolas, setSelectedEscolas] = useState<Escola[]>([])
    const [processing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false)
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)

    const api = useApi()

    useEffect(() => {
        setSelectedEscolas(relationships)

        api.getEscolas()
            .then(({ data }) => setEscolas(data))
            .catch(() => alert('Houve um erro ao buscar as escolas.'))
    }, []) /* eslint-disable-line */

    const getRelationships = () => (selectedEscolas.map(({id}) => id))

    const submit = () => {
        setProcessing(true)

        const relationships = getRelationships()

        api.changeGestorRelationships(relationships)
            .then(({data}) => {
                updateRelationships(data)

                setSuccess(true);
                setTimeout(() => goBack(), 2000)

                if (isMobile) setSnackbarIsOpen(true)
            })
            .catch(() => alert('Houve um erro ao mudar a escola'))
            .finally(() => setProcessing(false))
    }

    const handleAddEscola = (_: any, newEscola: Escola) => {
        const escolaIsNotInSelectedEscolas = !selectedEscolas.find((escola) => isEqual(newEscola, escola))

        if (escolaIsNotInSelectedEscolas) setSelectedEscolas([newEscola, ...selectedEscolas])
        else alert('Esta escola já foi adicionada')
    }

    const handleRemoveEscola = (escolaToRemove: Escola) => setSelectedEscolas(selectedEscolas.filter((escola) => !isEqual(escolaToRemove, escola)))

    return (
        <>
            <Autocomplete containerClassName={classes.autocomplete} placeholder="Nome da Escola Municipal"
                options={escolas} getOptionLabel={(option: Escola) => option.nome}
                label="Escola que você acompanha" onChange={handleAddEscola}
                message="Selecione uma escola"
            ></Autocomplete>
            <Scrollbar
                autoHeight
                autoHeightMax="30vh">
                {
                    selectedEscolas.map((escola) => (
                        <EscolaCard key={escola.id} escola={escola} handleRemoveEscola={handleRemoveEscola}></EscolaCard>
                    ))
                }
            </Scrollbar>            
            {!success ? (
                <Button
                    onClick={submit}
                    loading={processing}
                    disabled={!selectedEscolas.length}
                >
                    Salvar
                </Button>
            ) : (
                <SuccessButton onClick={goBack}>
                    Alterações Salvas ;)
                </SuccessButton>
            )}
            <Snackbar
                open={snackbarIsOpen}
                onClose={() => setSnackbarIsOpen(false)}
                message="Alterações salvas ;)"
            ></Snackbar>
        </>
    )
}

export default CoordenadorRelationshipsForm