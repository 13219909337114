import { Modal as MuiModal, ModalProps as MuiModalProps, makeStyles, Fade } from '@material-ui/core'

const Modal = (props: MuiModalProps) => {
    const classes = useStyles()

    return (<MuiModal {...props} className={classes.root} BackdropProps={{ timeout: 1000 }}>
        <Fade in={props.open}>
            {props.children}
        </Fade>
    </MuiModal>)
}

export default Modal

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))