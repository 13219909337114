/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Checkbox, ListItem, ListItemText } from "@material-ui/core";

import { isEqual } from "lodash";

import Autocomplete from "../../../components/Autocomplete";
import Scrollbar from "../../../components/Scrollbar";
import SuccessButton from "../../../components/SuccessButton";
import Button from "../../../components/Button";
import Snackbar from "../../../components/Snackbar";
import DistritoAccordion from "./DistritoAccordion";

import { useApi } from "../../../context/innerContext";
import { usePerfilContext } from "../../Perfil/context";

import useIsMobile from "../../../hooks/useIsMobile";

import { Distrito, DistritoAutocomplete } from "../../../global"
import { updateObjectsArray } from "../../../utils";

const AssessorRelationshipsForm = () => {
    const { goBack } = useHistory()
    const { relationships, updateRelationships } = usePerfilContext()
    const isMobile = useIsMobile()

    const [processing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false)
    const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(false)
    const [idsEscolasSelecionadas, setIdsEscolasSelecionadas] = useState<number[]>([])
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)
    const [distritosAutocomplete, setDistritosAutocomplete] = useState<DistritoAutocomplete[]>([])

    const selectedDistritosCount = distritosAutocomplete.filter(distrito => distrito.selected).length

    const api = useApi()

    const ALL_ID = -1
    const NO_ID = 0

    const noDistrito = {
        id: NO_ID,
        nome: '',
        escolas: []
    }

    const allDistritos = {
        id: ALL_ID,
        nome: 'Todos',
        escolas: []
    }

    const filterValidDistrito = (distrito: DistritoAutocomplete) => distrito.id > NO_ID
    const filterValidSelectedDistrito = (distrito: DistritoAutocomplete) => distrito.id > NO_ID && distrito.selected

    const getEscolasSelecionadasFromRelationships = (relationships: Distrito[]) => {
        const idsEscolasSelecionadas: number[] = [];

        if (!relationships) return []

        relationships.map(({ escolas }: Distrito) => escolas.map(({ id }) => idsEscolasSelecionadas.push(id)))
        return idsEscolasSelecionadas
    }

    useEffect(() => {
        const idsEscolasSelecionadas = getEscolasSelecionadasFromRelationships(relationships)

        setIdsEscolasSelecionadas(idsEscolasSelecionadas)

        api.getDistritos()
            .then(({ data }) => {
                const relatedDistritosIds: number[] = relationships.map(({ id }: Distrito) => id)

                const distritosAutocomplete = [noDistrito, allDistritos, ...data]
                    .map((distrito) => ({
                        ...distrito,
                        selected: distrito.id === ALL_ID ? data.length === relatedDistritosIds.length :
                            relatedDistritosIds.includes(distrito.id)
                    }))

                setDistritosAutocomplete(distritosAutocomplete)
            })
            .catch(() => alert('Houve um erro ao buscar os distritos.'))
    }, []) /* eslint-disable-line */

    useEffect(() => {
        const idsEscolasSelecionadasRelationships = getEscolasSelecionadasFromRelationships(relationships)

        const dataHasChanged = !isEqual(idsEscolasSelecionadasRelationships.sort(), idsEscolasSelecionadas.sort())
        setSubmitButtonIsDisabled(!dataHasChanged)
    }, [idsEscolasSelecionadas])

    const submit = async () => {
        if (!selectedDistritosCount) {
            alert('Selecione ao menos um distrito')
            return
        }

        // Verifica se todos os distritos possuem ao menos uma escola selecionada
        const todosDistritosSelecionadosPossuemEscola = distritosAutocomplete.filter(({ id, selected }) => id > NO_ID && selected).every(({ escolas }) =>
            escolas.some(escola => idsEscolasSelecionadas.includes(escola.id)))

        if (!todosDistritosSelecionadosPossuemEscola) {
            alert('Para todos os distritos selecionados, deve haver ao menos uma escola selecionada')
            return
        }

        setProcessing(true)

        await api.changeGestorRelationships(idsEscolasSelecionadas)

        setSuccess(true);
        setTimeout(() => goBack(), 2000)

        if (isMobile) setSnackbarIsOpen(true)

        setProcessing(false)

        const { data } = await api.getAssessorRelationships()

        updateRelationships(data)
    }

    const getIdsEscolasDistrito = (distrito: Distrito) => distrito.escolas.map(({ id }) => id)

    const removeDistrito = (distritoToRemove: Distrito) => {
        const idsEscolasSelecionadasToRemove = getIdsEscolasDistrito(distritoToRemove)

        const updatedIdsEscolasSelecionadas = idsEscolasSelecionadas.filter((id) => !idsEscolasSelecionadasToRemove.includes(id))
        setIdsEscolasSelecionadas(updatedIdsEscolasSelecionadas)

        const newDistritosAutocomplete = distritosAutocomplete.map(distrito => {
            if (distrito.id === distritoToRemove.id)
                return {
                    ...distrito,
                    selected: false
                }

            return distrito
        })

        const allDistritosAreSelected = distritosAutocomplete.find(distrito => distrito.id === ALL_ID)?.selected

        if (!allDistritosAreSelected) {
            setDistritosAutocomplete(newDistritosAutocomplete)
            return
        }

        const newestDistritosAutocomplete = updateObjectsArray([...newDistritosAutocomplete], { ...allDistritos, selected: true },
            { ...allDistritos, selected: false })

        setDistritosAutocomplete([...newestDistritosAutocomplete])
    }

    const handleAddEscola = (id: number) => setIdsEscolasSelecionadas([...idsEscolasSelecionadas, id])

    const handleRemoveEscola = (idToRemove: number) => setIdsEscolasSelecionadas(idsEscolasSelecionadas.filter((id) => id !== idToRemove))

    const handleAddAllEscolas = (distrito: Distrito) => {
        const idsEscolasToAdd = getIdsEscolasDistrito(distrito).filter((id) => !idsEscolasSelecionadas.includes(id))
        setIdsEscolasSelecionadas([...idsEscolasSelecionadas, ...idsEscolasToAdd])
    }

    const handleRemoveAllEscolas = (distrito: Distrito) => {
        const idsEscolasSelecionadasToRemove = getIdsEscolasDistrito(distrito)

        const updatedIdsEscolasSelecionadas = idsEscolasSelecionadas.filter((id) => !idsEscolasSelecionadasToRemove.includes(id))
        setIdsEscolasSelecionadas(updatedIdsEscolasSelecionadas)
    }

    const getDistritoAccordions = () => {
        if (distritosAutocomplete.length)
            return (
                distritosAutocomplete.filter(distrito => distrito.id > NO_ID && distrito.selected).map(distritoAutocomplete => (
                    <DistritoAccordion key={distritoAutocomplete.id} distrito={distritoAutocomplete} idsEscolasSelecionadas={idsEscolasSelecionadas} handleAddAllEscolas={handleAddAllEscolas} handleAddEscola={handleAddEscola} handleRemoveAllEscolas={handleRemoveAllEscolas} handleRemoveDistrito={removeDistrito} handleRemoveEscola={handleRemoveEscola} />
                ))
            )
    }

    const addDistrito = (distrito: DistritoAutocomplete) => {
        const newDistritosAutocomplete = updateObjectsArray([...distritosAutocomplete], distrito, { ...distrito, selected: true })
        const idsEscolas = distrito.escolas.map(({ id }) => id)

        setIdsEscolasSelecionadas([...idsEscolasSelecionadas, ...idsEscolas])

        const allDistritosSelected = newDistritosAutocomplete.filter(distrito => distrito.id > NO_ID).every(distrito => distrito.selected)

        if (!allDistritosSelected) {
            setDistritosAutocomplete(newDistritosAutocomplete)
            return
        }

        const newestDistritosAutocomplete = updateObjectsArray([...newDistritosAutocomplete], { ...allDistritos, selected: false }, { ...allDistritos, selected: true })
        setDistritosAutocomplete(newestDistritosAutocomplete)
    }

    const renderDistritoOption = (distrito: DistritoAutocomplete) => {
        if (distrito.id) {

            const handleCheck = (checked: boolean) => {
                if (distrito.id === ALL_ID) {
                    const newDistritosAutocomplete = distritosAutocomplete.map((distrito) => ({ ...distrito, selected: checked }))
                    setDistritosAutocomplete(newDistritosAutocomplete)

                    if (checked) {
                        const newIdsEscolasSelecionadas: number[] = []

                        distritosAutocomplete.forEach(({ escolas }) => {
                            const idsEscolas = escolas.map(({ id }) => id)
                            newIdsEscolasSelecionadas.push(...idsEscolas)
                        })

                        setIdsEscolasSelecionadas(newIdsEscolasSelecionadas)
                    }
                    else setIdsEscolasSelecionadas([])

                    return
                }

                if (checked) addDistrito(distrito)
                else removeDistrito(distrito)
            }

            return (
                <ListItem
                    onClick={() => handleCheck(!distrito.selected)}
                >
                    <Checkbox
                        checked={distrito.selected}
                        color="primary"
                    />
                    <ListItemText primary={distrito.nome} />
                </ListItem>
            )
        }

        return null
    }

    return (
        <>
            <Autocomplete placeholder="Digite o nome do distrito" options={distritosAutocomplete} getOptionLabel={(option: Distrito) => option.nome}
                label="Adicione o(s) distrito(s) em que você trabalha" renderOption={renderDistritoOption} disableCloseOnSelect={true} disabledItemsFocusable={true}></Autocomplete>
            <Scrollbar autoHeight
                autoHeightMax="30vh">
                <div></div>
                {getDistritoAccordions()}
                <div></div>
            </Scrollbar>
            {!success ? (
                <Button
                    onClick={submit}
                    loading={processing}
                    disabled={submitButtonIsDisabled}
                >
                    Salvar
                </Button>
            ) : (
                <SuccessButton onClick={goBack}>
                    Alterações Salvas ;)
                </SuccessButton>
            )}
            <Snackbar
                open={snackbarIsOpen}
                onClose={() => setSnackbarIsOpen(false)}
                message="Alterações salvas ;)"
            ></Snackbar>
        </>
    )
}

export default AssessorRelationshipsForm
