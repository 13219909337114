import { useContext } from 'react'
import Divider from '@material-ui/core/Divider'

import { CadastroContext, CadastroContextType } from '../../../context/cadastroContext'

import { Occupation } from '../../../global'

import useStyles, { Info, Label, InfoContainer, MiddleContainer, DadosAcompanhamento18Sized, DadosAcompanhamentoContainer, DadosAcompanhamentoLabel, OuterDatumAcompanhamento, InnerDatumAcompanhamento, InnerDataAcompanhamentoContainer } from './styles'
import Scrollbar from '../../../components/Scrollbar'

const StepFour = () => {
    const classes = useStyles()

    const { state: { email, nome, environment, funcao, selectedEscolas, selectedDistritos, cpf, telefone } } = useContext(CadastroContext) as CadastroContextType

    const renderDadosAcompanhamento = () => {
        switch (funcao?.role) {
            case Occupation.PROFESSOR: {
                return selectedEscolas.map(({ id, nome, turmas_disciplinas }) => (
                    <div key={id}>
                        <OuterDatumAcompanhamento>
                            {nome}
                        </OuterDatumAcompanhamento>
                        <InnerDataAcompanhamentoContainer>
                            {
                                turmas_disciplinas?.map(({ turma, disciplina }) => (
                                    <InnerDatumAcompanhamento key={`${turma.id}-${disciplina.id}`}>{`Turma ${turma.identificador}, ${disciplina.nome}.`}</InnerDatumAcompanhamento>
                                ))
                            }
                        </InnerDataAcompanhamentoContainer>
                    </div>

                ))
            }
            case Occupation.COORDENADOR:
            case Occupation.PEDAGOGO:
            case Occupation.GESTOR:
                {
                    return (
                        selectedEscolas.map((escola) => (
                            <div key={escola.id}>
                                <DadosAcompanhamento18Sized>{escola.nome}</DadosAcompanhamento18Sized>
                            </div>
                        ))
                    )
                }
            case Occupation.ASSESSOR: {
                return selectedDistritos.map(({ distrito, selectedEscolas }) => (
                    <div key={distrito.id}>
                        <OuterDatumAcompanhamento>
                            {distrito.nome}
                        </OuterDatumAcompanhamento>
                        <InnerDataAcompanhamentoContainer>
                            {distrito.escolas.length !== selectedEscolas.length &&
                                selectedEscolas.map((escola) => (
                                    <InnerDatumAcompanhamento key={escola.id}>{escola.nome}</InnerDatumAcompanhamento>
                                ))
                            }
                        </InnerDataAcompanhamentoContainer>
                    </div>
                ))
            }
            default: return null
        }
    }

    return (
        <>
            <div>
                <InfoContainer>
                    <Label>Email:</Label>
                    <Info>{email}</Info>
                </InfoContainer>
                <Divider className={classes.divider}></Divider>
                <MiddleContainer>
                    <InfoContainer>
                        <Label>Nome:</Label>
                        <Info>{nome}</Info>
                    </InfoContainer>
                    <InfoContainer>
                        <Label>Ambiente:</Label>
                        <Info>{environment?.name}</Info>
                    </InfoContainer>
                    <InfoContainer>
                        <Label>CPF:</Label>
                        <Info>{cpf}</Info>
                    </InfoContainer>
                    <InfoContainer>
                        <Label>Telefone:</Label>
                        <Info>{telefone}</Info>
                    </InfoContainer>
                </MiddleContainer>
                <Divider className={classes.divider}></Divider>
                <InfoContainer>
                    <Label>Função:</Label>
                    <Info>{funcao?.description}</Info>
                </InfoContainer>
            </div>
            <DadosAcompanhamentoContainer>
                <DadosAcompanhamentoLabel>Dados de acompanhamento</DadosAcompanhamentoLabel>
                <Scrollbar
                    style={{ height: "98%" }}
                >
                    {renderDadosAcompanhamento()}
                </Scrollbar>
            </DadosAcompanhamentoContainer>
        </>
    )
}

export default StepFour