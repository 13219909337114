import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

export const SelectedTurmaContainer = styled.div`
    display: flex;
    margin-top: 24px;
`

export const TurmaDisciplinaLabel = styled.span`
    font-size: 16px;
    flex: 1;
    text-align: left;
`

export const EscolaModalTitle = styled.p`
    font-weight: 500;
    font-size: 24px;
`

export const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
`

export const ContinueEditing = styled.span`
    color: #0295FF;
    text-decoration-line: underline;
    font-size: 16px;
    font-weight: normal;
    padding: 0 15px;
`

export const EscolaModalWarning = styled.p`
    font-weight: 500;
    font-size: 16px;
    margin-top: 8px;
`

export const AccordionSummary = styled.div<{ expanded: boolean }>`
    display: flex;
    padding: 8px 16px ${props => props.expanded ? '0' : '8px'};
`

export const EscolaLabel = styled.p`
    // text-align: left;
    // font-size: 18px;
    // font-weight: 400;

    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const Hint = styled.p`
    text-align: left;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    margin-bottom: 24px;
`

const useStyles = makeStyles(() => ({
    root: {
        border: '2px solid #A5D9FF',
        boxShadow: '1px 2px 5px rgba(55, 55, 72, 0.08)',
        borderRadius: 12,
        marginTop: 8
    },
    title: {
        textAlign: 'left',
        fontSize: 18
    },
    subheader: {
        textAlign: 'left',
        fontSize: 14
    },
    dropdownsContainer: {
        width: '100%'
    },
    iconButton: {
        padding: '0 7px'
    },
    removeEscolamodal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalCard: {
        boxShadow: '2px 8px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: 20,
        width: 380
    },
    modalCardWebWidth: {
        width: 380
    },
    modalCardMobileWidth: {
        width: '85%'
    },
    modalCardHeader: {
        paddingBottom: 0,
        paddingRight: 24,
        paddingLeft: 24
    },
    modalTitle: {
        fontWeight: 'bold',
        fontSize: 16
    },
    modalCardContent: {
        paddingTop: 0,
        paddingRight: 24,
        paddingLeft: 24
    },
    removeEscolaButton: {
        width: 189
    },
    addButton: {
        marginTop: 24
    },
    accordionDetails: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        rowGap: 8,
        padding: '0 16px 16px'
    },
    paper: {
        maxHeight: 500
    }
}))

export default useStyles