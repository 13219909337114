import styled from 'styled-components'

import finishedRegisterBackground from '../../assets/finished-register-background.png'
import check from '../../assets/check.png'

export const Container = styled.div`
    height: 100%;
    background: url(${finishedRegisterBackground}) no-repeat center center fixed;
    background-size: cover;
    padding-top: 184px;

    @media(max-width: 959px) {
        padding-top: 8vh;
    }
`

export const ContentContainer = styled.div`
    margin: auto;
    width: 350px;
    text-align: center;

    @media(max-width: 959px) {
        padding: 0 44px;
    }
`

export const Check = styled.img.attrs(() => ({ src: check, alt: 'check' }))`
    margin: auto;
`

export const Title = styled.p`
    font-weight: 700;
    color: white;
    font-size: 36px;
    margin-top: 16px;

    @media(max-width: 959px) {
        font-size: 28px;        
    }
`

export const Subtitle = styled.p`
    font-weight: 500;
    color: white;
    font-size: 24px;
    margin-top: 16px;

    @media(max-width: 959px) {
        font-size: 18px;        
    }
`

export const GreenBorderContainer = styled.div`
    border: 5px #3DE7C4 solid;
    border-radius: 20px;
    margin-top: 40px;
    padding: 30px;

    @media(max-width: 959px) {
        margin: 20px auto 0;
        padding: 20px;
    }
`

export const LoginIs = styled.p`
    font-weight: 500;
    color: white;
    font-size: 24px;

    @media(max-width: 959px) {
        font-size: 18px;        
    }
`

export const Login = styled.p`
    font-weight: 700;
    color: white;
    font-size: 36px;
    margin-top: 8px;

    @media(max-width: 959px) {
        font-size: 28px;        
    }
`