import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    text-align: left;
`

export const StyledInput = styled.input<{ uppercase?: boolean }>` 
    font-family: Ubuntu, sans-serif;
    margin-right: 10px;
    flex: 1;

    &:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
	}

    ${props => props.readOnly && `
        cursor: default;
    `}
    
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    user-select: text;
`

export const InputWrapper = styled.div<{ error?: boolean }>`
    display: flex;
    border-radius: 8px;
    padding: 12px;
    border-width: ${props => props.error ? '2' : '1'}px;
    border-style: solid;
    border-color: ${props => props.error ? '#FC4821' : '#8F8E94'};
    width: 100%;
    margin: 8px 0;
    height: 48px;
    background-color: white;
`

export const Label = styled.label`
    font-size: 14px;
    color: #373748;
`

export const ErrorMessage = styled.p<{ visible?: boolean }>`
    color: #FC4821;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    font-size: 12px;
`

export const ShimmerPlaceholder = styled.div`
    margin-right: 10px;
    background-color: rgb(205, 205, 205);
    flex: 1;
    animation: animation 2s infinite ease;

    @keyframes animation {
        from {
            background-repeat: no-repeat;
            background-image: -webkit-linear-gradient( top left, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.0) 45%, rgba(255, 255, 255, 0.5) 48%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 52%, rgba(255, 255, 255, 0.0) 57%, rgba(255, 255, 255, 0.0) 100% );
            background-position: -250px -250px;
            background-size: 500px 600px;
        }

        to {
            background-repeat: no-repeat;
            background-position: 250px 250px;
        }
        
    }
`
