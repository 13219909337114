import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100%;

    @media(max-width: 959px) {
        flex-direction: column;
    }
`

export const ChildrenContainer = styled.div`
    flex: 1;
`

