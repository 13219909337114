import { SvgIcon } from "@material-ui/core";
import styled from "styled-components";
import { ReactComponent as edit } from '../../assets/edit.svg'

export default styled(SvgIcon).attrs(() => ({
    component: edit
}))`
    path {
        stroke: black;
        fill: none;
    }

    transition: all .25s;

    :hover {
        path {
            stroke: #0295FF;
        }
    }
    
`