/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"

import { isEqual } from "lodash"

import Autocomplete from "../../../components/Autocomplete"
import Button from "../../../components/Button"
import Scrollbar from "../../../components/Scrollbar"
import SuccessButton from "../../../components/SuccessButton"
import Snackbar from "../../../components/Snackbar"
import EscolaCard from "../../../components/EscolaCard"

import useIsMobile from "../../../hooks/useIsMobile"

import { usePerfilContext } from "../../Perfil/context"
import { useApi } from "../../../context/innerContext"

import { Disciplina, Escola, EscolaTurmas, Turma, TurmaDisciplina } from "../../../global"

import { arrayContainsObject, updateObjectsArray } from "../../../utils"

const ProfessorRelationshipsForm = () => {
    const { goBack } = useHistory()
    const { relationships, updateRelationships } = usePerfilContext()
    const api = useApi()
    const isMobile = useIsMobile()

    const [escolas, setEscolas] = useState<Escola[]>()
    const [selectedEscolas, setSelectedEscolas] = useState<Escola[]>([])
    const [escolasTurmas, setEscolasTurmas] = useState<EscolaTurmas[]>([])
    const [disciplinas, setDisciplinas] = useState<Disciplina[]>([])

    const [processing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false)
    const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(true)
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)

    useEffect(() => {
        api.getEscolas({ professor: true })
            .then(({ data }) => setEscolas(data))
            .catch(() => alert('Houve um erro ao buscar as escolas.'))

        api.getDisciplinas()
            .then(({ data }) => setDisciplinas(data))
            .catch(() => alert('Houve um erro ao buscar as disciplinas.'))

        return () => { }
    }, [])

    useEffect(() => {
        setSelectedEscolas(relationships)
    }, [relationships])

    const getNewRelationships = () => {
        const relationships: { disciplina_id: number, turma_id: number }[] = []
        selectedEscolas.forEach(({ turmas_disciplinas }) => turmas_disciplinas!.forEach(({ disciplina, turma }) => relationships.push({ disciplina_id: disciplina.id, turma_id: turma.id })))
        return relationships
    }

    useEffect(() => {
        const dataHasChanged = !isEqual(relationships, selectedEscolas)
        setSubmitButtonIsDisabled(!dataHasChanged)

        selectedEscolas && selectedEscolas.filter((escola) => !escolasTurmas.find((escolaTurma) => escolaTurma.id === escola.id))
            .forEach((escola) => {
                api.getTurmas(escola.id)
                    .then(({ data }) => {
                        const turmas = [...data]

                        turmas.forEach((turma) => delete turma['serie'])

                        setEscolasTurmas((escolasTurmas) => [...escolasTurmas, { id: escola.id, turmas: turmas }])

                    })
                    .catch(() => alert('Houve um erro ao buscar as turmas da escola.'))
            })
    }, [selectedEscolas])

    const getEscolaCard = useCallback((escola: Escola) => {
        const turmas = escolasTurmas.find((escolaTurmas) => escolaTurmas.id === escola.id)?.turmas || []

        return (
            <EscolaCard key={escola.id} escola={escola} handleAddAllDisciplinas={handleAddAllDisciplinas} handleAddAllTurmas={handleAddAllTurmas}
                handleAddAllTurmasDisciplinas={handleAddAllTurmasDisciplinas} handleAddTurmaDisciplina={handleAddTurmaDisciplina} handleRemoveEscola={removeEscola}
                handleRemoveTurmaDisciplina={handleRemoveTurmaDisciplina} turmas={turmas} disciplinas={disciplinas}></EscolaCard>
        )
    }, [escolasTurmas, disciplinas])

    const submit = () => {
        if (!selectedEscolas.length) {
            alert('Selecione ao menos uma escola')
            return
        }

        const todasEscolasPossuemTurmasDisciplinas = selectedEscolas.every((escola) => escola.turmas_disciplinas!.length)

        if (!todasEscolasPossuemTurmasDisciplinas) {
            alert('Para todas as escolas selecionadas, deve haver ao menos uma turma associada')
            return
        }

        const relationships = getNewRelationships()

        setProcessing(true)

        api.changeProfessorRelationships(relationships)
            .then(({ data }) => {
                updateRelationships(data)

                setSuccess(true);
                setTimeout(() => goBack(), 2000)

                if (isMobile) setSnackbarIsOpen(true)
            })
            .catch(() => alert('Houve um erro ao mudar a escola'))
            .finally(() => setProcessing(false))
    }

    const handleAddEscola = (newEscola: Escola) => {
        const escolaIsNotInSelectedEscolas = !selectedEscolas.find((escola) => escola.id === newEscola.id)

        if (escolaIsNotInSelectedEscolas) setSelectedEscolas([{ ...newEscola, turmas_disciplinas: [] }, ...selectedEscolas])
        else alert('Esta escola já foi adicionada')
    }

    const handleRemoveEscola = (escolaToRemove: Escola) => setSelectedEscolas(selectedEscolas.filter((escola) => !isEqual(escolaToRemove, escola)))

    const removeEscola = (escolaToRemove: Escola) => {
        const newEscolasTurmas = escolasTurmas.filter((escolaTurma) => escolaTurma.id !== escolaToRemove.id)
        setEscolasTurmas(newEscolasTurmas)
        handleRemoveEscola(escolaToRemove)
    }

    const handleAddTurmaDisciplina = (turma: Turma, disciplina: Disciplina, oldEscola: Escola) => {
        const turmaDisciplinaToAdd: TurmaDisciplina = { turma, disciplina }

        if (arrayContainsObject(oldEscola.turmas_disciplinas!, turmaDisciplinaToAdd)) {
            alert('Esta turma e disciplina já foram adicionadas')
            return
        }

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            turmaDisciplinaToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        // Passagem de cópias dos arrays com o spread operator para evitar efeitos colaterais ao passar a referência em si do array que representa o state das escolas
        const newSelectedEscolas = updateObjectsArray([...selectedEscolas], oldEscola, newEscola)

        setSelectedEscolas([...newSelectedEscolas])
    }

    const handleAddAllDisciplinas = (turma: Turma, disciplinas: Disciplina[], oldEscola: Escola) => {
        // Filtra as turmas-disciplinas que não estão nas turmas-disciplinas da escola e as retorna, assim evitando duplicatas
        const turmasDisciplinasToAdd = disciplinas
            .filter((disciplina) => !arrayContainsObject(oldEscola.turmas_disciplinas!, { turma, disciplina }))
            .map((disciplina) => ({ turma, disciplina }))

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            ...turmasDisciplinasToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        const newSelectedEscolas = updateObjectsArray(selectedEscolas, oldEscola, newEscola)

        setSelectedEscolas([...newSelectedEscolas])
    }

    const handleAddAllTurmas = (disciplina: Disciplina, turmas: Turma[], oldEscola: Escola) => {
        // Filtra as turmas-disciplinas que não estão nas turmas-disciplinas da escola e as retorna, assim evitando duplicatas
        const turmasDisciplinasToAdd = turmas
            .filter((turma) => !arrayContainsObject(oldEscola.turmas_disciplinas!, { turma, disciplina }))
            .map((turma) => ({ turma, disciplina }))

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            ...turmasDisciplinasToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        const newSelectedEscolas = updateObjectsArray(selectedEscolas, oldEscola, newEscola)

        setSelectedEscolas([...newSelectedEscolas])
    }

    const handleAddAllTurmasDisciplinas = (turmas: Turma[], disciplinas: Disciplina[], oldEscola: Escola) => {
        const turmasDisciplinasToAdd = []

        for (let i = 0; i < turmas.length; i++)
            for (let j = 0; j < disciplinas.length; j++) {
                const turmaDisciplinaToAdd = {
                    turma: turmas[i],
                    disciplina: disciplinas[j]
                }

                if (!arrayContainsObject(oldEscola.turmas_disciplinas!, turmaDisciplinaToAdd))
                    turmasDisciplinasToAdd.push(turmaDisciplinaToAdd)
            }

        const selectedTurmasDisciplinas: TurmaDisciplina[] = [
            ...oldEscola.turmas_disciplinas!,
            ...turmasDisciplinasToAdd
        ]

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        const newSelectedEscolas = updateObjectsArray([...selectedEscolas], oldEscola, newEscola)

        setSelectedEscolas([...newSelectedEscolas])
    }

    const handleRemoveTurmaDisciplina = (turmaDisciplinaToRemove: TurmaDisciplina, oldEscola: Escola) => {
        const oldSelectedTurmasDisciplinas = oldEscola.turmas_disciplinas!
        const selectedTurmasDisciplinas = oldSelectedTurmasDisciplinas.filter((turmaDisciplina) => !isEqual(turmaDisciplina, turmaDisciplinaToRemove))

        const newEscola: Escola = {
            ...oldEscola,
            turmas_disciplinas: selectedTurmasDisciplinas
        }

        // Passagem de cópias dos arrays com o spread operator para evitar efeitos colaterais ao passar a referência em si do array que representa o state das escolas
        const newSelectedEscolas = updateObjectsArray([...selectedEscolas], oldEscola, newEscola)

        setSelectedEscolas([...newSelectedEscolas])
    }

    const renderAutoComplete = useCallback(() => {
        if (!escolas) return null

        return escolas.length ? (
            <Autocomplete placeholder="Digite o nome da sua escola" options={escolas} getOptionLabel={(option) => option.nome} inputValue=""
                label="Adicione a(s) escola(s) em que você trabalha" onChange={(_: any, newEscola: Escola) => handleAddEscola(newEscola)}></Autocomplete>
        ) : 'Ainda não há escolas com turmas ativas do ano corrente para você se vincular.'
    }, [escolas])

    return (
        <>
            {renderAutoComplete()}
            <Scrollbar
                autoHeight
                autoHeightMax="30vh">
                {
                    selectedEscolas && selectedEscolas.map((escola) => getEscolaCard(escola))
                }
            </Scrollbar>
            {!success ? (
                <Button
                    onClick={submit}
                    loading={processing}
                    disabled={submitButtonIsDisabled}
                >
                    Salvar
                </Button>
            ) : (
                <SuccessButton onClick={goBack}>
                    Alterações Salvas ;)
                </SuccessButton>
            )}
            <Snackbar
                open={snackbarIsOpen}
                onClose={() => setSnackbarIsOpen(false)}
                message="Alterações salvas ;)"
            ></Snackbar>
        </>
    )
}

export default ProfessorRelationshipsForm