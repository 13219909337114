import { createActions, createReducer } from 'reduxsauce';

// interfaces
interface ProfileActions {
  SET_PROFILE: string;
  SET_PROFILE_UPDATABLE_DATA: string;
  SET_PASSWORD: string;
  RESET: string;
}

interface ProfileCreators {
  setProfile: (profileData: ProfileStore) => any;
  setProfileUpdatableData: (data: ProfileUpdatableData) => any;
  setPassword: (new_password: string) => any;
  reset: () => any;
}

export interface ProfileUpdatableData {
  nome?: string
  cpf?: string
  telefone_celular?: string
  data_nascimento?: string
  email?: string
}

export interface UpdatePassword {
  new_password: string
}

export interface ProfileStore {
  nome: string;
  email: string;
  username: string;
  cpf: string;
  telefone_celular: string;
  data_nascimento: string;
  role?: number;
  relationships: any[],
  occupation?: number
  occupation_display: string
}

// initial state
const INITIAL_STATE: ProfileStore = ({
  nome: '',
  email: '',
  username: '',
  cpf: '',
  telefone_celular: '',
  data_nascimento: '',
  relationships: [],
  occupation_display: ''
});

// reducers
const setProfile = (_: any, action: any) => action.profileData;
const setProfileUpdatableData = (state = INITIAL_STATE, action: any) => ({ ...state, ...action.data });
const setPassword = (state = INITIAL_STATE, action: any) => ({ ...state, ...action.new_password });
const reset = () => INITIAL_STATE

// actions
export const { Types: ProfileTypes, Creators: ProfileCreators } = createActions<ProfileActions, ProfileCreators>({ /* eslint-disable-line */
  setProfile: ['profileData'],
  setProfileUpdatableData: ['data'],
  setPassword: ['new_password'],
  reset: [],
});

// reducers handlers
export default createReducer(INITIAL_STATE, {
  [ProfileTypes.SET_PROFILE]: setProfile,
  [ProfileTypes.SET_PROFILE_UPDATABLE_DATA]: setProfileUpdatableData,
  [ProfileTypes.SET_PASSWORD]: setPassword,
  [ProfileTypes.SET_PASSWORD]: setPassword,
  [ProfileTypes.RESET]: reset,
});
