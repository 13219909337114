import styled from 'styled-components'
import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => createStyles({
    modalCard: {
        boxShadow: '2px 8px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: 20,
        width: 380
    },
    modalCardWebWidth: {
        width: 380
    },
    modalCardMobileWidth: {
        width: '85%'
    },
    modalCardHeader: {
        paddingBottom: 0
    },
    modalCardContent: {
        paddingTop: 0,
        paddingRight: 24,
        paddingLeft: 24
    },
    removeEscolaButton: {
        width: 189
    }
}))

export const StepperContainer = styled.div`
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 8vh 0;
`

export const StepsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media(max-width: 959px) {
        .MuiStepper-root {
            width: 54%;
        }
    }
`

export const ButtonsContainer = styled.div`
    border-top: 1px solid #CBC9D5;
    margin: 24px 85px 0;
    padding: 15px 85px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;

    @media(max-width: 959px) {
        padding: 0;
        margin: 0;
        border: 0;
        margin-top: 8px;
        grid-template-columns: 1fr 3fr;
    }
`

export const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
`

export const ContinueEditing = styled.span`
    color: #0295FF;
    text-decoration-line: underline;
    font-size: 16px;
    font-weight: normal;
    padding: 0 15px;
`

export const Escola = styled.p`
    font-weight: 500;
    font-size: 24px;
`

export const TurmaDisciplina = styled.p`
    font-weight: 500;
    font-size: 16px;
    margin-top: 8px;
`

export const StepContainer = styled.div`
    margin: 0 170px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media(max-width: 959px) {
        margin: 22px 0 0;
    }
`

export const HighlightedUsername = styled.span`
    color: #0295FF;
    font-weight: bold;
`