import { InputHTMLAttributes, useState } from "react";

import {
  Container,
  StyledInput,
  Label,
  ErrorMessage,
  InputWrapper,
  Eye
} from "./styles";

import eyeOn from '../../assets/eye-on.svg'
import eyeOff from '../../assets/eye-off.svg'

const Input = ({
  label,
  error,
  message,
  ...inputProps
}: InputProps) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)
  const [focused, setFocused] = useState(false)

  return (
    <Container>
      <Label>{label}</Label>
      <InputWrapper error={error} focused={focused}>
        <StyledInput
          {...inputProps}
          type={passwordIsVisible ? 'text' : 'password'}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        ></StyledInput>
        {passwordIsVisible ? <Eye src={eyeOff} onClick={() => setPasswordIsVisible(false)}></Eye> : <Eye src={eyeOn} onClick={() => setPasswordIsVisible(true)}></Eye>}
      </InputWrapper>
      <ErrorMessage visible={error}>{message}</ErrorMessage>
    </Container>
  )
}

export default Input;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  message?: string;
  label: string
}