import styled, { css } from "styled-components";
import makeStyles from "@material-ui/core/styles/makeStyles";

import loginPageBackground from '../../assets/login-page-background.png'

export const useStyles = makeStyles(() => ({
    container: {
        height: '100%'
    },
    overlay: {
        height: '100%',
        position: 'absolute',
        zIndex: 1
    },
    webTransition: {
        transition: 'left .8s ease-in-out, width .8s ease-in-out, border-radius .8s ease-in-out'
    },
    login: {
        width: '55vw',
        borderBottomRightRadius: 120,
        display: 'flex',
        left: 0
    },
    cadastro: {
        left: '50%',
        borderBottomLeftRadius: 120,
        borderTopLeftRadius: 120,
        width: '50vw',
        backgroundColor: 'white'
    }
}))

export const Container = styled.div<{ screen: string }>`
    background: url(${loginPageBackground}) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

    @media(max-width: 959px) {
        .MuiGrid-container {
            justify-content: center;
        }

        #paper {
            width: 100%;
            padding-top: 116px;
            background-color: transparent;
            box-shadow: none;

            ${props => props.screen === 'cadastro' &&
        css`
                    position: initial;
                    background-color: white;
                    border-radius: 0;
                    padding: 12px 24px 8px;
                `
    }            
        }
    }    
`

export const SideContainer = styled.div`
    color: white;
    width: 313px;
    margin: auto;
    font-weight: 700;
`

export const Heading1 = styled.h1`
    margin-bottom: 16px;
`

export const Heading2 = styled.h2`
    margin-bottom: 16px;
`

export const Heading3 = styled.h3`
    font-weight: 500;
`

export const FeatureContainer = styled.div`
    margin-bottom: 16px;
`

export const SignUpRedirectContainer = styled.div`
    color: white;
    width: 313px;
    margin: auto;
    font-weight: 700;
`