import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core";

import loginPageBackground from "../../assets/login-page-background.png";

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100%",
    },
    overlay: {
      borderBottomRightRadius: 120,
      width: "55vw",
      height: "100%",
      display: 'flex'

    },
    rightSide: {
      display: 'flex'
    }
  })
);

export const Container = styled.div`
  background: url(${loginPageBackground}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;

  @media(max-width: 959px) {
    .MuiGrid-container {
        justify-content: center;
    }

    #paper {
      width: 100%;
      padding-top: 116px;
      background-color: transparent;
      box-shadow: none;
    }
  }  
`;

export const SignUpRedirectContainer = styled.div`
  color: white;
  width: 313px;
  margin: auto;
  font-weight: 700;
`;

export const Heading1 = styled.h1`
  margin-bottom: 16px;
`;

export const Heading2 = styled.h2`
  margin-bottom: 16px;
  line-height: 42px;
`;

export const RedirectToSignUpButton = styled.button`
  background-color: #0295ff;
  font-size: 18px;
  padding: 12px 0;
  color: white;
  font-weight: 700;
  width: 100%;
`;

export const RecoverPasswordForm = styled.div`
  width: 360px;
  margin: auto;
  display: grid;
  row-gap: 24px;
  .text-align{
    text-align: center;
  }

  @media(max-width: 959px) {
    margin: 0 auto;
    width: 80%;
    p, label, a {
      color: white;
      text-align: center;
    }
  }
`;

export const Title = styled.p`
  text-align: left;
  font-size: 28px;
  font-weight: 700;
`;

export const Subtitle = styled.p`
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const BacktoLogin = styled.a`
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  text-decoration-line: underline;
  color: #373748;
`;

export const ErrorMessage = styled.p<{ visible: boolean }>`
color: #FC4821;
font-weight: 500;
text-align: center;
visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`