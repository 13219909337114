import { useContext, useState } from 'react'
import { Card, CardHeader, CardContent, IconButton, useMediaQuery } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'

import Autocomplete from '../../../components/Autocomplete'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import UnderlinedTextButton from '../../../components/UnderlinedTextButton'

import Professor from './Professor'
import Coordenador from './Coordenador'
import Assessor from './Assessor'

import { CadastroContext, CadastroContextType } from '../../../context/cadastroContext'

import { Environment, Funcao, Occupation } from '../../../global'

import useStyles, { ModalButtonsContainer } from './styles'

const StepThree = ({ roleError }: { roleError: boolean }) => {
    const isMobile = useMediaQuery('(max-width: 959px)')

    const classes = useStyles()

    const { state: { funcao }, handleChangeFuncao } = useContext(CadastroContext) as CadastroContextType

    const [changeRoleModalIsOpen, setChangeRoleModalIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [innerFuncao, setInnerFuncao] = useState<Funcao>()

    const changeFuncaoAutocomplete = (_: any, funcaoAutocomplete: Funcao) => {
        if (funcao && funcao.role !== funcaoAutocomplete.role) {
            setChangeRoleModalIsOpen(true)
            setInnerFuncao(funcaoAutocomplete)
        }
        else handleChangeFuncao(funcaoAutocomplete)
    }

    const confirmFuncaoChange = () => {
        handleChangeFuncao(innerFuncao!)
        setChangeRoleModalIsOpen(false)
    }

    const funcoes: Funcao[] = [
        {
            role: Occupation.ASSESSOR,
            description: 'Assessor Distrital'
        },
        {
            role: Occupation.COORDENADOR,
            description: 'Coordenador'
        },
        {
            role: Occupation.GESTOR,
            description: 'Gestor'
        },
        {
            role: Occupation.PEDAGOGO,
            description: 'Pedagogo'
        },
        {
            role: Occupation.PROFESSOR,
            description: 'Professor'
        }
    ]

    const closeGoToStepFourModalAction = (
        <IconButton onClick={() => setChangeRoleModalIsOpen(false)}>
            <CloseIcon></CloseIcon>
        </IconButton>
    )

    return (
        <>
            <Autocomplete containerClassName={classes.autocomplete} placeholder="Selecione sua função"
                options={funcoes} getOptionLabel={(option: Funcao) => option.description}
                label="Informe sua função abaixo" onChange={changeFuncaoAutocomplete} value={funcao} inputValue={inputValue} onInputChange={setInputValue} error={roleError} message="Selecione a sua função"></Autocomplete>
            {
                funcao?.role === Occupation.PROFESSOR ? <Professor ></Professor> :
                    funcao?.role === Occupation.ASSESSOR ? <Assessor></Assessor> : <Coordenador></Coordenador>
            }
            <Modal open={changeRoleModalIsOpen}>
                <Card className={clsx(classes.modalCard, isMobile ? classes.modalCardMobileWidth : classes.modalCardWebWidth)}>
                    <CardHeader className={classes.modalCardHeader} action={closeGoToStepFourModalAction}></CardHeader>
                    <CardContent className={classes.modalCardContent}>
                        Deseja realmente mudar de função? Os dados para acompanhamento serão excluídos.
                        <ModalButtonsContainer>
                            <UnderlinedTextButton onClick={() => setChangeRoleModalIsOpen(false)}>Voltar a editar</UnderlinedTextButton>
                            <Button className={classes.removeEscolaButton} onClick={confirmFuncaoChange}>Confirmar</Button>
                        </ModalButtonsContainer>
                    </CardContent>
                </Card>
            </Modal>
        </>
    )

}

export default StepThree

export interface StepThreeProps {
    environment?: Environment
}