import { Route, Switch } from "react-router-dom";

import CadastroLogin from '../pages/CadastroLogin'
import RecoverPassword from '../pages/RecuperarSenha'

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={CadastroLogin} />
      <Route path="/recuperar-senha" component={RecoverPassword} />
    </Switch>
  );
};

export default PublicRoutes;
