import { useState, useEffect, useContext } from "react"
import { useLocation } from 'react-router-dom'

import { Grid, Hidden, Paper, useMediaQuery } from "@material-ui/core"

import clsx from 'clsx'

import Button from "../../components/Button"

import Login from "../Login"
import Cadastro from "../Cadastro"
import FinishedRegister from "../FinishedRegister"

import CadastroProvider, { CadastroContext, CadastroContextType } from "../../context/cadastroContext"

import { useStyles, Container, Heading1, Heading2, SideContainer, Heading3, SignUpRedirectContainer, FeatureContainer } from "./styles"

const CadastroLogin = () => {
    const isMobile = useMediaQuery('(max-width: 959px)')

    const classes = useStyles()
    const { state } = useLocation()

    const [screen, setScreen] = useState<Screen>('login')
    const [overlayStyle, setOverlayStyle] = useState(classes.overlay);

    const { state: { finishedRegister, step } } = useContext(CadastroContext) as CadastroContextType

    useEffect(() => {
        // @ts-ignore
        if (state && state.goToCadastro) setScreen('cadastro')
    }, []) // eslint-disable-line

    useEffect(() => {
        setOverlayStyle(
            clsx(
                classes.overlay, screen === 'login' ? classes.login : classes.cadastro,
                !isMobile && classes.webTransition
            )
        )
    }, [screen]) /* eslint-disable-line */

    return (
        finishedRegister ? <FinishedRegister></FinishedRegister> :
            <Container screen={screen}>
                <Paper id="paper" className={overlayStyle}>
                    {
                        screen === 'login' ? <Login toggleScreen={() => setScreen('cadastro')}></Login> : <Cadastro toggleScreen={() => setScreen('login')}></Cadastro>
                    }
                </Paper>
                <Hidden smDown>
                    <Grid container className={classes.container} >
                        <Grid item md={6} style={{ display: 'flex' }}>
                            {
                                step === 0 ?
                                    (
                                        <SideContainer>
                                            <Heading1>Já fez seu cadastro?</Heading1>
                                            <Heading2>Se seu cadastro já foi aprovado, clique abaixo para fazer seu login.</Heading2>
                                            <Button onClick={() => setScreen('login')}>FAZER LOGIN</Button>
                                        </SideContainer>

                                    ) : (
                                        <SideContainer>
                                            <h1>Atenção</h1>
                                            <br /><br />
                                            <Heading3>Se você atua em mais de uma escola, possui mais de uma turma ou leciona mais de uma disciplina, todas as informações devem ser adicionadas nesse único cadastro.</Heading3>
                                            <br /><br />
                                            <Heading3>Mas não se preocupe, posteriormente você poderá adicionar ou excluir informações.</Heading3>
                                        </SideContainer>
                                    )
                            }
                        </Grid>
                        <Grid item md={6} style={{ display: 'flex' }}>
                            <SignUpRedirectContainer id="sign-up">
                                <Heading2>Inclusão e conectividade transformando a Educação</Heading2>
                                <FeatureContainer>
                                    <Heading3>Gestor de Desempenho</Heading3>
                                    <p>Acompanhe o engajamento dos estudantes, gere relatórios, visualize gráficos e muito mais.</p>
                                </FeatureContainer>
                                <FeatureContainer>
                                    <Heading3>Soluções</Heading3>
                                    <p>Acesse os apps Super Ensino Games e Super Aluno para aproveitá-los ao máximo em sala de aula.</p>
                                </FeatureContainer>
                                <FeatureContainer>
                                    <Heading3>Não tem conta?</Heading3>
                                    <p>Cadastre-se agora mesmo!</p>
                                </FeatureContainer>
                                <Button onClick={() => setScreen('cadastro')}>FAZER CADASTRO</Button>
                            </SignUpRedirectContainer>
                        </Grid>
                    </Grid>
                </Hidden>
            </Container>
    )
}

const CadastroLoginProvider = () => (
    <CadastroProvider>
        <CadastroLogin></CadastroLogin>
    </CadastroProvider>
)

export default CadastroLoginProvider

type Screen = 'login' | 'cadastro'