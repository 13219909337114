import styled from "styled-components";
import InputMask from 'react-input-mask'

export const Container = styled.div`
    width: 100%;
    text-align: left;
`

export const StyledInput = styled(InputMask) <{ error?: boolean }>`
    border-radius: 8px;
    padding: 12px;
    border-width: ${props => props.error ? '2' : '1'}px;
    border-style: solid;
    border-color: ${props => props.error ? '#FC4821' : '#8F8E94'};
    width: 100%;
    margin: 8px 0;
    height: 48px;
    font-family: Ubuntu, sans-serif;

    :focus {
        border: 2px solid #0295FF;
    }

    &:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
	}

    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    user-select: text;
`

export const Label = styled.label`
    font-size: 14px;
    color: #373748;
`

export const ErrorMessage = styled.p<{ visible?: boolean }>`
    color: #FC4821;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    font-size: 12px;
`