import styled from "styled-components";

const CustomLink = (props: CustomLinkProps) => {
    const Link = styled.a.attrs(() => ({
        href: props.link,
        target: '_blank'
    }))`
            color: #0295FF;
            text-decoration: underline;
        `

    return <Link>{props.label}</Link>
}

export default CustomLink

interface CustomLinkProps {
    link: string
    label: string
}