import { ReactNode } from "react";
import {
  makeStyles,
  Card,
  createStyles,
  Theme,
  CardContent,
  useMediaQuery,
  CardHeader,
  IconButton,
  SvgIcon,
} from "@material-ui/core";

import Modal from "../Modal";

import { ReactComponent as close } from "../../assets/icons/clear.svg";

const ModalForm = ({
  title,
  open,
  onCloseModal,
  children,
}: ModalFormProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 959px)");

  return (
    <Modal open={open}>
      <Card className={classes.card}>
        {!isMobile && (
          <CardHeader
            className={classes.cardHeader}
            action={
              <IconButton onClick={onCloseModal}>
                <SvgIcon component={close}></SvgIcon>
              </IconButton>
            }
          ></CardHeader>
        )}
        <CardContent className={classes.cardContent}>
          <p className={classes.title}>
            {isMobile && (
              <SvgIcon
                className={classes.mobileCloseButton}
                component={close}
                onClick={onCloseModal}
              ></SvgIcon>
            )}
            {title}
          </p>
          {children}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ModalForm;

interface ModalFormProps {
  onCloseModal: () => void;
  title: string;
  children: ReactNode;
  open: boolean;
  submit?: () => void
  processing?: boolean
  success?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      width: 615,
      padding: 24,
      paddingBottom: 44,
      boxShadow: "2px 8px 24px rgba(0, 0, 0, 0.15)",
      borderRadius: 30,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        borderRadius: 0,
        padding: 0,
      },
    },
    cardHeader: {
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        padding: 24,
        paddingTop: 14,
        paddingBottom: 0,
        flex: "inherit",
      },
    },
    cardContent: {
      paddingBottom: 0,
      paddingLeft: 32,
      paddingRight: 32,
      display: "grid",
      rowGap: 24,
      [theme.breakpoints.down("sm")]: {
        rowGap: 16,
      },
    },
    title: {
      padding: 0,
      fontWeight: 500,
      fontSize: 24,
      color: "#373748",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        display: "flex",
        alignItems: "center",
      },
    },
    mobileCloseButton: {
      marginRight: 27,
      cursor: "pointer",
    },
  })
);
