import styled from 'styled-components'

export default styled.button`
    width: 100%;
    height: 51px;
    font-weight: 700;
    font-size: 18px;
    font-family: Ubuntu, sans-serif;
    color: #6B6878;
    border-radius: 12px;
    border: 2px solid #0295FF;
    cursor: pointer;    
    background: transparent;
	transition: transform .25s;

    &:hover, &:active {
		transform: scale(.9);
	}

    // span over radial background
    span {
        position: relative;
		z-index: 1;
    }
`