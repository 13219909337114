import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';

import { GlobalStyles, MuiTheme } from './global'
import Routes from "./routes/index";
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/es/integration/react';

const App = () => (
  <BrowserRouter>
    <ThemeProvider theme={MuiTheme}>
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
