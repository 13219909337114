// @ts-nocheck
import axios from "axios";
import { findIndex, isEqual } from "lodash"
import { REACT_APP_STAGING_URL, REACT_APP_IS_DEV, REACT_APP_WORKSPACES_MANAGER_DOMAIN } from "./env";

export const arrayContainsObject = <T>(array: T[], obj: T) => {
    for (let i = 0; i < array.length; i++) {
        const arrayObj = array[i]

        const areEquals = isEqual(obj, arrayObj)

        if (areEquals) return areEquals
    }

    return false
}

export const updateObjectsArray = <T>(array: T[], oldObj: T, newObj: T) => {
    const index = findIndex(array, oldObj)

    array.splice(index, 1, newObj)

    return array
}

export const formatCpf = (cpf?: string) => {
    if (!cpf) return ''

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
}

/**
 * Retorna o CPF em uma string de apenas dígitos
 * @param cpf  
 */
export const unformatCpf = (cpf?: string) => {
    if (!cpf) return ''

    return cpf.replace(/[^\d]/g, "")
}

export const formatTelefone = (telefone?: string) => {
    if (!telefone) return ''

    if (telefone.length === 11) return telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
    else if (telefone.length === 10) return telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
    else return 'Não foi possível formatar o número de telefone'
}

/**
 * Retorna o telefone em uma string de apenas dígitos
 * @param cpf  
 */
export const unformatTelefone = (telefone?: string) => {
    if (!telefone) return ''

    return telefone.replace(/[^\d]/g, "")
}

export const phoneIsValid = (phone: string) => {
    const re = /^\(\d{2}\) \d{5}-\d{3}[\d_]$/

    return re.test(phone)
}

export const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'

export const getEnvironment = async (username: string) => {
    if (REACT_APP_STAGING_URL)
        return {
            id: 0,
            name: 'Staging Environment',
            domain_url: REACT_APP_STAGING_URL
        }

    if (REACT_APP_IS_DEV)
        return {
            id: 0,
            name: 'Development Environment',
            domain_url: 'localhost:8000'
        }

    const { data } = await axios.get(`https://${REACT_APP_WORKSPACES_MANAGER_DOMAIN}/api/find/workspaces/`, {
        params: { username },
    });

    if (!data.length) return undefined;

    return data[0];
};