import { useState } from "react"
import { Accordion, AccordionDetails, Checkbox, IconButton } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';

import { Distrito, Escola } from "../../../../global"

import useStyles, { NomeEscola, EscolaContainer, DistritoLabel, Hint, ExcluirLabel, AccordionSummary, RemovingConfirmation, RemovingChoice } from "./styles"

const DistritoAccordion = ({ distrito, idsEscolasSelecionadas, handleAddAllEscolas, handleAddEscola, handleRemoveAllEscolas, handleRemoveDistrito, handleRemoveEscola }: DistritoAccordionProps) => {
    const classes = useStyles()

    const [expanded, setExpanded] = useState(false)
    const [requestedRemoving, setRequestedRemoving] = useState(false)

    const deleteDistritoAction = (
        <IconButton onClick={() => setRequestedRemoving(true)} className={classes.deleteButton}>
            <ExcluirLabel>Excluir</ExcluirLabel>
            <CloseIcon></CloseIcon>
        </IconButton>
    )

    const handleCheck = (checked: boolean, idEscola: number) => checked ? handleAddEscola(idEscola) : handleRemoveEscola(idEscola)
    const handleCheckAll = (checked: boolean) => checked ? handleAddAllEscolas(distrito) : handleRemoveAllEscolas(distrito)

    const allEscolasAreSelected = (escolas: Escola[]) => escolas.every(({ id }) => idsEscolasSelecionadas.includes(id))

    const confirmDistritoRemoving = (
        <RemovingConfirmation>
            <span>Deseja mesmo excluir?</span>
            <RemovingChoice onClick={() => handleRemoveDistrito(distrito)}>Sim</RemovingChoice>
            <RemovingChoice onClick={() => setRequestedRemoving(false)}>Não</RemovingChoice>
        </RemovingConfirmation>
    )

    return (
        <Accordion expanded={expanded} className={classes.root}>
            <AccordionSummary expanded={expanded}>
                <DistritoLabel onClick={() => setExpanded(!expanded)}>{distrito.nome}</DistritoLabel>
                {requestedRemoving ? confirmDistritoRemoving : deleteDistritoAction}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Hint>Selecione abaixo a(s) escola(a)s que você acompanha</Hint>
                <EscolaContainer>
                    <NomeEscola>SELECIONAR TODAS AS ESCOLAS</NomeEscola>
                    <Checkbox className={classes.checkbox} checked={allEscolasAreSelected(distrito.escolas)} onChange={(_, checked) => handleCheckAll(checked)}></Checkbox>
                </EscolaContainer>
                {distrito.escolas.map((escola) => (
                    <EscolaContainer key={escola.id}>
                        <NomeEscola>{escola.nome}</NomeEscola>
                        <Checkbox className={classes.checkbox} checked={idsEscolasSelecionadas.includes(escola.id)} onChange={(_, checked) => handleCheck(checked, escola.id)}></Checkbox>
                    </EscolaContainer>
                ))}
            </AccordionDetails>
        </Accordion>
    )
}

export default DistritoAccordion

interface DistritoAccordionProps {
    distrito: Distrito
    idsEscolasSelecionadas: number[]
    handleRemoveDistrito: (distrito: Distrito) => void
    handleAddEscola: (id: number) => void
    handleRemoveEscola: (id: number) => void
    handleAddAllEscolas: (distrito: Distrito) => void
    handleRemoveAllEscolas: (distrito: Distrito) => void
}
