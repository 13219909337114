import { useContext, useState } from 'react'

import Input from '../../../components/Input'
import MaskedInput from '../../../components/MaskedInput'
import DatePicker from '../../../components/DatePicker'

import { CadastroContext, CadastroContextType } from '../../../context/cadastroContext'

import { MaskedInputContainer } from './styles'

import UsernameReadonlyInput from './UsernameReadonlyInput'

const StepTwo = ({ cpfError, dataNascimentoError, telefoneError, usernameError }: StepTwoProps) => {
    const [usernameIsLoading, setUsernameIsLoading] = useState(false)
    const [erroNome, setErroNome] = useState('')

    const { api, state, setState } = useContext(CadastroContext) as CadastroContextType

    const handleNomeBlur = () => {
        if (!/[^A-ù\s]/.test(state.nome) && /^[A-ù]+\s+\w[A-ù]/.test(state.nome)) {
            setUsernameIsLoading(true)

            api.generateUsername(state.nome)
                .then(({ data }) => setState({ ...state, username: data.nome }))
                .catch(() => alert('Houve um erro ao gerar o username.'))
                .finally(() => setUsernameIsLoading(false))
        }
        else setErroNome('O nome deve conter no mínimo dois nomes, sem abreviações e sem duplo espaço entre os nomes.')
    }

    return (
        <>
            <Input name="nome" label="Nome" placeholder="Digite seu nome completo" setState={setState} error={!!erroNome} message={erroNome} onBlur={handleNomeBlur} state={state} value={state.nome}></Input>
            <UsernameReadonlyInput state={state} setState={setState} loading={usernameIsLoading} error={usernameError}></UsernameReadonlyInput>
            <MaskedInputContainer>
                <MaskedInput label="CPF" placeholder="Digite o seu CPF" mask="999.999.999-99" onChange={(e) => setState({ ...state, cpf: e.target.value })} message="Insira um CPF válido" error={cpfError} value={state.cpf}></MaskedInput>
                <MaskedInput label="Telefone" placeholder="Digite o seu telefone" mask="(99) 99999-9999" onChange={(e) => setState({ ...state, telefone: e.target.value })} message="Insira um telefone válido" error={telefoneError} value={state.telefone}></MaskedInput>
                <DatePicker label="Data de nascimento" onChange={(date) => setState({ ...state, data_nascimento: date })} value={state.data_nascimento} message="Insira uma data de nascimento válida" error={dataNascimentoError} />
            </MaskedInputContainer>
        </>
    )
}

export default StepTwo

export interface StepTwoProps {
    cpfError: boolean
    telefoneError: boolean
    dataNascimentoError: boolean
    usernameError: boolean
}