
import { useState } from "react"
import { useHistory } from "react-router"

import { validate } from "email-validator"

import useIsMobile from "../../hooks/useIsMobile"

import Button from "../../components/Button"
import Input from "../../components/Input"
import ModalForm from "../../components/ModalForm"
import Snackbar from "../../components/Snackbar"
import SuccessButton from "../../components/SuccessButton"

import { useApi } from "../../context/innerContext"
import { usePerfilContext } from "../Perfil/context"

const UserEmailUpdate = () => {
    const api = useApi()
    const isMobile = useIsMobile()
    const { goBack } = useHistory()
    const { updateEmail } = usePerfilContext()

    type State = {
        email: string
    }

    const [state, setState] = useState<State>({
        email: ''
    });
    const [emailError, setEmailError] = useState(false);
    const [success, setSuccess] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)

    const { email } = state

    const handleSubmitEmail = () => {
        if (!validate(email)) {
            setEmailError(true);
            return;
        }

        setProcessing(true);

        api
            .changeUserData({ email })
            .then(() => {
                updateEmail(email)

                setSuccess(true);
                setTimeout(() => goBack(), 2000)

                if (isMobile) setSnackbarIsOpen(true)
            })
            .catch(() => alert("Houve um erro ao tentar atualizar o e-mail"))
            .finally(() => setProcessing(false));
    };

    // const handleChangeEmail = (email: string) => {
    //     emailError && setEmailError(false);
    //     setEmail(email);
    // }

    return (
        <ModalForm
            open={true}
            title="Editar e-mail"
            onCloseModal={goBack}
        >
            <Input
                placeholder="Digite seu email"
                message="Insira um e-mail válido"
                error={emailError}
                label="Email"
                state={state}
                setState={setState}
                name="email"
            ></Input>
            {!success ? (
                <Button
                    onClick={handleSubmitEmail}
                    loading={processing}
                    disabled={!email}
                >
                    Salvar
                </Button>
            ) : (
                <SuccessButton onClick={goBack}>
                    Alterações Salvas ;)
                </SuccessButton>
            )}
            <Snackbar
                open={snackbarIsOpen}
                onClose={() => setSnackbarIsOpen(false)}
                message="Alterações salvas ;)"
            ></Snackbar>
        </ModalForm>
    )
}

export default UserEmailUpdate