import styled from 'styled-components'
import { makeStyles, createStyles } from '@material-ui/core'

export default makeStyles(() => createStyles({
    divider: {
        margin: '16px 0'
    }
}))

export const InfoContainer = styled.div`
    text-align: left;
`

export const Label = styled.span`
  font-size: 18px;
  font-weight: 500; 
  color: #4E4B59; 
`

export const OuterDatumAcompanhamento = styled.p`
    font-size: 18px;
    font-weight: normal; 
    color: #4E4B59; 
    margin-bottom: 4px;
`

export const InnerDatumAcompanhamento = styled.p`
    font-size: 16px;
    font-weight: normal; 
    color: #4E4B59; 
`

export const DadosAcompanhamento18Sized = styled.span`
    font-size: 18px;
    font-weight: normal; 
    color: #4E4B59; 
`

export const DadosAcompanhamento16Sized = styled.span`
  font-size: 18px;
  font-weight: normal; 
  color: #4E4B59; 
`

export const Info = styled.span`
  font-size: 18px;
  font-weight: normal; 
  color: #4E4B59; 
  margin-left: 8px;
`

export const MiddleContainer = styled.div`
    display: grid;
    row-gap: 8px;
`

export const DadosAcompanhamentoContainer = styled.div`
    margin-top: 16px;
    text-align: left;
    flex: 1;
`

export const DadosAcompanhamentoLabel = styled.p`
  font-size: 18px;
  font-weight: 500; 
  color: #4E4B59; 
  margin-bottom: 8px;
`

export const InnerDataAcompanhamentoContainer = styled.div`
    margin-bottom: 8px;
`