import { Link, Route, useRouteMatch } from "react-router-dom";

import {
  Card,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";

import clsx from 'clsx'

import InformacoesBasicasUpdate from "../../InformacoesBasicasUpdate";
import EditPencil from "../../../components/EditPencil";

import { usePerfilContext } from "../context";

import { Store } from "../../../store";

import { formatCpf, formatTelefone } from "../../../utils";

import {
  useStyles,
  CardTitle,
  Data,
  Label,
  Value,
} from "../styles";
import { useSelector } from "react-redux";

const InformacoesBasicas = () => {
  const classes = useStyles();

  const { profile: { nome, cpf, telefone_celular, data_nascimento } } = usePerfilContext()
  const { path } = useRouteMatch()

  const env = useSelector((state: Store) => state.auth.environment)

  const informacoes = [
    { campo: 'nome', valor: nome },
    { campo: 'cpf', valor: formatCpf(cpf) || 'CPF não cadastrado' },
    { campo: 'telefone', valor: formatTelefone(telefone_celular) || 'Telefone não cadastrado' },
    { campo: 'data_nascimento', valor: data_nascimento || 'Data de nascimento não cadastrada' },
  ]

  return (
    <Card className={clsx(classes.card, classes.infosBasicasCard)}>
      <CardContent className={classes.cardContent} classes={{
        root: classes.noPaddingBottom
      }}>
        <CardTitle>Informações Básicas</CardTitle>
        <Grid container>
          <Grid item xs={10}>
            <Grid container item>
              <Grid item xs={12}>
                <Label>ambiente</Label>
              </Grid>
              <Grid item xs={12}>
                <Value>{env?.name}</Value>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider}></Divider>
        <Grid container>
          <Grid item xs={10}>
            {informacoes.map(({ campo, valor }, index) => (
              <Grid container item key={`binfo-${index}`}>
                <Grid item xs={12}>
                  <Data>{campo}</Data>
                </Grid>
                <Grid key={index}>
                  <Value>{valor}</Value>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs>
            <Link to={`${path}/informacoes-basicas/update`}>
              <Value className={classes.svgIcon}>
                <EditPencil />
              </Value>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
      <Route path={`${path}/informacoes-basicas/update`} component={InformacoesBasicasUpdate}></Route>
    </Card>
  );
};

export default InformacoesBasicas;