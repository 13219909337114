import axios, { AxiosInstance } from "axios";

import { Distrito, Escola, Profile } from "../global";

import { ProfileUpdatableData } from "../store/ducks/profile";

import { protocol } from "../utils";

class Api {
  private api: AxiosInstance;

  constructor(domainUrl: string, headers?: any) {
    this.api = axios.create({
      baseURL: `${protocol}://${domainUrl}/api`,
      headers
    });
  }

  getDistritos = () => this.api.get<Distrito[]>('cadastro-gestores/distritos/')

  getEscolas = (params?: {distrito__id?: number, professor?: any}) => this.api.get<Escola[]>('cadastro-gestores/escola-search/', { params })

  getDisciplinas = () => this.api.get('cadastro-gestores/disciplinas/')

  getTurmas = (escola__id?: number) => this.api.get('cadastro-gestores/turmas/', { params: { escola__id } })

  generateUsername = (nome: string) => this.api.post('cadastro-gestores/generate-username/', { nome })

  register = (data: RegisterData) => this.api.post('cadastro-gestores/register/', data)

  changePassword = (new_password: string, old_password: string) => this.api.put('user/password/change/', { new_password, old_password })

  changeUserData = (data: ProfileUpdatableData) => this.api.put('user/profile/data/', data)

  getProfessorRelationships = () => this.api.get<Escola[]>(`cadastro-gestores/professor-relationships/`)

  getCoordenadorRelationships = () => this.api.get<Escola[]>(`cadastro-gestores/gestor-relationships/`)

  getAssessorRelationships = () => this.api.get(`cadastro-gestores/assessor-relationships/`)

  changeProfessorRelationships = (turmas_disciplinas: { disciplina_id: number, turma_id: number }[]) => this.api.post('cadastro-gestores/professor-relationships/link/', { turmas_disciplinas })

  changeGestorRelationships = (ids_escolas: number[]) => this.api.post('cadastro-gestores/gestor-relationships/link/', { ids_escolas })

  getPowerBiAccessToken = () => this.api.post('microsoft-auth/')

  getReportsFromGroup = (groupId: string, token: string) => this.api.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports`, { headers: { Authorization: `Bearer ${token}` } })

  getProfile = () => this.api.get<Profile>('user/profile/data/')

  checkRoleCpfExists = (role: number, cpf: string, is_manager?: boolean) => this.api.get<RoleCpfExists>(`cadastro-gestores/cpf/${cpf}/exists/`, is_manager ? { params: { is_manager } } : undefined)

  updateGestorData = (data: UpdateGestorData) => this.api.put('cadastro-gestores/update-register/', data)

  getProfessorEscolas = () => this.api.get<Escola[]>(`cadastro-gestores/professor-escolas/`)
}

export default Api;

export interface RegisterData {
  occupation: number
  is_manager: boolean
  username: string
  senha: string
  nome: string
  email: string
  cpf: string
  telefone: string
  data_nascimento: string
  turmas_disciplinas?: { turma_id: number, disciplina_id: number }[]
  escolas?: number[]
}

interface RoleCpfExists {
  role_with_cpf_exists: boolean
  username?: string
  approved?: boolean
}

export interface UpdateGestorData {
  email: string
  data_nascimento: string
  telefone: string
  cpf: string
  role: number
  turmas_disciplinas?: { turma_id: number, disciplina_id: number }[]
  escolas?: number[]
}