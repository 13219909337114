import { Props } from 'react-input-mask'

import {
  Container,
  StyledInput,
  Label,
  ErrorMessage
} from "./styles";

const NumberFormatInput = ({
  label,
  error,
  message,
  ...inputProps
}: NumberFormatInputProps) => (
  <Container>
    <Label>{label}</Label>
    <StyledInput
      error={error}
      {...inputProps}
    ></StyledInput>
    <ErrorMessage visible={error}>{message}</ErrorMessage>
  </Container>
)

export default NumberFormatInput;

interface NumberFormatInputProps extends Props {
  error?: boolean;
  message?: string;
  label: string
}