import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    autocomplete: {
        marginBottom: 24
    },
    scrollbar: {
        flex: 1
    }
}))

export default useStyles