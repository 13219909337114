import { useState, useContext } from 'react'
import { Card, CardContent, CardHeader, IconButton, Step, StepLabel, useMediaQuery } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { validate as validateCpf } from 'gerador-validador-cpf'
import { validate as validateEmail } from 'email-validator'
import format from 'date-fns/format'
import clsx from 'clsx'

import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import Modal from '../../components/Modal'
import UnderlinedTextButton from '../../components/UnderlinedTextButton';

import Stepper from './Stepper'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour';

import { CadastroContext, CadastroContextType } from '../../context/cadastroContext'

import { Occupation } from '../../global'

import { useStyles, StepperContainer, ButtonsContainer, ModalButtonsContainer, ContinueEditing, StepsWrapper, StepContainer, HighlightedUsername } from './styles'
import { RegisterData } from '../../services';
import { phoneIsValid } from '../../utils';
import CustomLink from '../../components/CustomLink';

const getStepTitle = (step: number) => {
  switch (step) {
    case 0: return 'Criação de usuário'
    case 1: return 'Dados Pessoais'
    case 2: return 'Dados para acompanhamento'
    case 3: return 'Para finalizar, confirme suas informações'
    default: return ''
  }
}

const Cadastro = ({ toggleScreen }: CadastroProps) => {
  const isMobile = useMediaQuery('(max-width: 959px)')

  const classes = useStyles()

  const { api, setState, state } = useContext(CadastroContext) as CadastroContextType

  const steps = ['', '', '', '']

  const { selectedEscolas, selectedDistritos, confirmation, cpf, data_nascimento, email, nome, password, step, telefone, username, environment, funcao } = state

  const [goToStepFourModalIsOpen, setGoToStepFourModalIsOpen] = useState(false)
  const [registerIsProcessing, setRegisterIsProcessing] = useState(false)
  const [checkingRoleWithCpfExists, setCheckingRoleWithCpfExists] = useState(false)
  const [profileApproved, setProfileApproved] = useState(false)
  const [roleWithCpfExists, setRoleWithCpfExists] = useState(false)
  const [existingUsername, setExistingUsername] = useState('')

  const [stepOneErrors, setStepOneErrors] = useState({
    environmentError: false,
    emailError: false,
    passwordError: false,
    confirmationError: false
  })

  const [stepTwoErrors, setStepTwoErrors] = useState({
    cpfError: false,
    telefoneError: false,
    dataNascimentoError: false,
    usernameError: false
  })

  const [roleError, setRoleError] = useState(false)

  const handleClickBackButton = () => {
    if (step) setState({ ...state, step: step - 1 })
    else toggleScreen()
  }

  const stepThreeIsValid = (role?: number) => {
    switch (role) {
      case Occupation.PROFESSOR:
        const allEscolasHaveTurma = selectedEscolas.every(({ turmas_disciplinas }) => turmas_disciplinas!.length)
        return selectedEscolas.length && allEscolasHaveTurma
      case Occupation.COORDENADOR:
      case Occupation.GESTOR:
      case Occupation.PEDAGOGO:
        return !!selectedEscolas.length
      case Occupation.ASSESSOR:
        const allDistritosHaveEscola = selectedDistritos.every(({ selectedEscolas }) => selectedEscolas.length)
        return selectedDistritos.length && allDistritosHaveEscola
      default: break;
    }
  }

  const stepIsValid = () => {
    switch (state.step) {
      case 0: return environment && email && password && confirmation
      case 1: return nome && username && cpf && phoneIsValid(telefone) && data_nascimento
      case 2: return stepThreeIsValid(funcao?.role)
      case 3: return step === 3
      default: return false;
    }
  }

  const validateStepOne = () => {
    setStepOneErrors({
      environmentError: false,
      emailError: false,
      passwordError: false,
      confirmationError: false
    })

    let isValid = true

    if (!environment) {
      setStepOneErrors({
        ...stepOneErrors,
        environmentError: true
      })
      isValid = false
    }

    if (!email || !validateEmail(email)) {
      setStepOneErrors({
        ...stepOneErrors,
        emailError: true
      })
      isValid = false
    }

    if (!password) {
      setStepOneErrors({
        ...stepOneErrors,
        passwordError: true
      })
      isValid = false
    }

    if (!confirmation || password !== confirmation) {
      setStepOneErrors({
        ...stepOneErrors,
        confirmationError: true
      })
      isValid = false
    }

    return isValid
  }

  const validateStepTwo = () => {
    setStepTwoErrors({
      cpfError: false,
      telefoneError: false,
      dataNascimentoError: false,
      usernameError: false
    })

    let isValid = true

    if (!cpf || !validateCpf(cpf)) {
      setStepTwoErrors({
        ...stepTwoErrors,
        cpfError: true
      })
      isValid = false
    }

    if (!username) {
      setStepTwoErrors({
        ...stepTwoErrors,
        usernameError: true
      })
      isValid = false
    }

    if (!phoneIsValid(telefone)) {
      setStepTwoErrors({
        ...stepTwoErrors,
        telefoneError: true
      })
      isValid = false
    }

    if (!data_nascimento) {
      setStepTwoErrors({
        ...stepTwoErrors,
        dataNascimentoError: true
      })
      isValid = false
    }

    return isValid
  }

  const validateStepThree = () => {
    setRoleError(false)

    if (!funcao) {
      setRoleError(true)
      return false
    }

    let isValid = true
    let errorMessage = ''

    switch (funcao?.role) {
      case Occupation.PROFESSOR:
        if (!selectedEscolas.length) {
          errorMessage = 'Selecione ao menos uma escola'
          isValid = false
          break
        }

        const allEscolasHaveTurma = selectedEscolas.every(({ turmas_disciplinas }) => turmas_disciplinas?.length)

        if (!allEscolasHaveTurma) {
          errorMessage = 'Todas as escolas adicionadas devem ter uma turma associada'
          isValid = false
          break
        }
        break
      case Occupation.COORDENADOR:
      case Occupation.PEDAGOGO:
      case Occupation.GESTOR:
        if (!selectedEscolas.length) {
          errorMessage = 'Selecione uma escola'
          isValid = false
          break
        }
        break
      case Occupation.ASSESSOR:
        if (!selectedDistritos.length) {
          errorMessage = 'Selecione ao menos um distrito'
          isValid = false
          break
        }

        const allDistritosHaveEscola = selectedDistritos.every(({ selectedEscolas }) => selectedEscolas.length)

        if (!allDistritosHaveEscola) {
          errorMessage = 'Todos os distritos adicionados devem ter uma escolas associada'
          isValid = false
          break
        }
        break
      default: break;
    }

    if (isValid) return true

    alert(errorMessage)

    return false
  }

  const validateCurrentStep = () => {
    switch (step) {
      case 0: return validateStepOne()
      case 1: return validateStepTwo()
      case 2: return validateStepThree()
      case 3: return true
      default: return false;
    }
  }

  const goToNextStep = () => {
    if (!validateCurrentStep()) return

    if (step === 2) {
      if (goToStepFourModalIsOpen) {
        setGoToStepFourModalIsOpen(false)

        if (!roleWithCpfExists) setState({ ...state, step: step + 1 })
      }
      else checkRoleCpfExists()
    }
    else if (step === 3) register()
    else setState({ ...state, step: step + 1 })
  }

  const closeGoToStepFourModalAction = (
    <IconButton onClick={() => setGoToStepFourModalIsOpen(false)}>
      <CloseIcon></CloseIcon>
    </IconButton>
  )

  const getTurmas = () => {
    const turmas: { turma_id: number, disciplina_id: number }[] = []
    selectedEscolas.forEach(({ turmas_disciplinas }) => turmas_disciplinas?.forEach(({ disciplina, turma }) => turmas.push({ disciplina_id: disciplina.id, turma_id: turma.id })))
    return turmas
  }

  const getEscolas = () => {
    switch (funcao?.role) {
      case Occupation.COORDENADOR:
      case Occupation.PEDAGOGO:
      case Occupation.GESTOR:
        return selectedEscolas.map(({ id }) => id)
      case Occupation.ASSESSOR:
        const escolas: any = []
        selectedDistritos.forEach(({ selectedEscolas }) => selectedEscolas.forEach(({ id }) => escolas.push(id)))
        return escolas
      default:
        break;
    }
  }

  /**
   * Verifica se existe na base um perfil com a combinação ROLE-CPF já cadastrado na base
   */
  const checkRoleCpfExists = () => {
    const formattedCpf = cpf.replace(/[^\d]/g, '')

    setCheckingRoleWithCpfExists(true)

    api.checkRoleCpfExists(funcao?.role!, formattedCpf, !(funcao?.role === Occupation.PROFESSOR))
      .then(({ data }) => {
        const roleWithCpfExists = data.role_with_cpf_exists

        setRoleWithCpfExists(roleWithCpfExists)

        if (roleWithCpfExists) {
          setGoToStepFourModalIsOpen(true)
          setProfileApproved(data.approved!)

          setExistingUsername(data.username!)
        }
        else setState({ ...state, step: step + 1 })
      })
      .finally(() => setCheckingRoleWithCpfExists(false))
  }

  const register = () => {
    setRegisterIsProcessing(true)

    const formattedCpf = cpf.replace(/[^\d]/g, '')
    const formattedTelefone = telefone.replace(/[^\d]/g, '')
    const formattedDataNascimento = format(data_nascimento!, 'yyyy-MM-dd')

    const data: RegisterData = {
      occupation: funcao?.role!, email, nome, username, senha: password, telefone: formattedTelefone, cpf: formattedCpf, data_nascimento: formattedDataNascimento,
      is_manager: !(funcao?.role === Occupation.PROFESSOR)
    }

    switch (funcao?.role) {
      case Occupation.PROFESSOR:
        data.turmas_disciplinas = getTurmas()
        break;
      default:
        data.escolas = getEscolas()
        break;
    }

    api.register(data)
      .then(() => setState({ ...state, finishedRegister: true }))
      .catch(() => alert('Houve um erro ao realizar o cadastro.'))
      .finally(() => setRegisterIsProcessing(false))

  }

  return (<>
    <StepperContainer>
      <StepsWrapper>
        <h3>{getStepTitle(step)}</h3>
        <Stepper activeStep={step}>
          {
            steps.map((_, index) => <Step key={index}><StepLabel></StepLabel></Step>)
          }
        </Stepper>
        <StepContainer>
          {
            step === 0 ? <StepOne {...stepOneErrors}></StepOne> :
              step === 1 ? <StepTwo {...stepTwoErrors}></StepTwo> :
                step === 2 ? <StepThree roleError={roleError}></StepThree> :
                  <StepFour></StepFour>
          }
        </StepContainer>
      </StepsWrapper>
      <ButtonsContainer>
        {
          isMobile ? (
            <UnderlinedTextButton onClick={handleClickBackButton}>Voltar</UnderlinedTextButton>
          ) : (
            <BackButton onClick={handleClickBackButton}>Voltar</BackButton>
          )
        }
        <Button onClick={goToNextStep} disabled={!stepIsValid()} loading={registerIsProcessing || checkingRoleWithCpfExists}>{step === 3 ? 'Finalizar' : 'Próximo'}</Button>
      </ButtonsContainer>
    </StepperContainer>
    <Modal open={goToStepFourModalIsOpen}>
      <Card className={clsx(classes.modalCard, isMobile ? classes.modalCardMobileWidth : classes.modalCardWebWidth)}>
        <CardHeader className={classes.modalCardHeader} action={closeGoToStepFourModalAction}></CardHeader>
        <CardContent className={classes.modalCardContent}>
          {
            profileApproved ? (
              <p>Já existe um perfil aprovado com esse CPF, com o login: <HighlightedUsername>{existingUsername}</HighlightedUsername>. Se você esqueceu a senha, crie uma nova senha <CustomLink link="/recuperar-senha" label="aqui"></CustomLink>.</p>
            ) : (
              <p>Já existe um perfil com esse CPF aguardando aprovação, com o login: <HighlightedUsername>{existingUsername}</HighlightedUsername>. Um e-mail será enviado quando o perfil for aprovado.</p>
            )
          }
          <ModalButtonsContainer>
            <ContinueEditing onClick={() => setGoToStepFourModalIsOpen(false)}>Voltar a editar</ContinueEditing>
            <Button className={classes.removeEscolaButton} onClick={() => setGoToStepFourModalIsOpen(false)}>OK</Button>
          </ModalButtonsContainer>
        </CardContent>
      </Card>
    </Modal>
  </>
  )
}

export default Cadastro

interface CadastroProps {
  toggleScreen: () => void
}