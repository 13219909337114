import { useState, useEffect, useContext } from 'react'
import axios from 'axios'

import Autocomplete from '../../../components/Autocomplete'
import Input from '../../../components/Input'
import PasswordInput from '../../../components/PasswordInput'

import { CadastroContext, CadastroContextType } from '../../../context/cadastroContext'

import { Environment } from '../../../global'


import { Container } from './styles'

import { REACT_APP_IS_DEV, REACT_APP_STAGING_URL, REACT_APP_WORKSPACES_MANAGER_DOMAIN } from '../../../env'

const StepOne = ({ confirmationError, emailError, environmentError, passwordError }: StepOneProps) => {
    const [environments, setEnvironments] = useState<Environment[]>([])

    const { handleChangeEnvironment, state, setState } = useContext(CadastroContext) as CadastroContextType

    useEffect(() => {
        if (REACT_APP_IS_DEV) {
            const localEnvironment = {
                id: 0,
                name: 'Development Environment',
                domain_url: 'localhost:8000'
            }

            setEnvironments([localEnvironment])
        }
        else if (REACT_APP_STAGING_URL) {
            const stagingEnvironment = {
                id: 0,
                name: 'Staging Environment',
                domain_url: REACT_APP_STAGING_URL
            }

            setEnvironments([stagingEnvironment])
        }
        else {
            axios.get(`https://${REACT_APP_WORKSPACES_MANAGER_DOMAIN}/api/workspaces/`)
                .then(({ data }) => setEnvironments(data))
                .catch(() => alert('Houve um erro ao retornar os ambientes'))
        }
    }, []) /* eslint-disable-line */

    return (
        <Container>
            <Autocomplete placeholder="Selecione um ambiente"
                options={environments} getOptionLabel={(option) => option.name} defaultValue={state.environment}
                label="De qual ambiente você faz parte?" onChange={handleChangeEnvironment} message="Selecione um ambiente" error={environmentError}></Autocomplete>
            <Input name="email" label="E-mail" placeholder="Digite seu e-mail" message="Insira um email válido" error={emailError} state={state} setState={setState} value={state.email}></Input>
            <PasswordInput label="Senha" placeholder="Digite sua senha" value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })} message="Insira uma senha válida" error={passwordError}></PasswordInput>
            <PasswordInput label="Confirme sua senha" placeholder="Digite sua senha novamente" value={state.confirmation} onChange={(e) => setState({ ...state, confirmation: e.target.value })} message="As senhas devem ser coincidentes" error={confirmationError}></PasswordInput>
        </Container>
    )
}

export default StepOne

export interface StepOneProps {
    environmentError: boolean
    emailError: boolean
    passwordError: boolean
    confirmationError: boolean
}