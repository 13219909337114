import { InputHTMLAttributes, ReactNode, useRef } from "react";

import {
  Container,
  StyledInput,
  Label,
  ErrorMessage
} from "./styles";

const Input = ({
  state,
  setState,
  label,
  error,
  message,
  endAdornment,
  readOnly,
  loading,
  uppercase,
  onBlur,
  ...inputProps
}: InputArgs) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  return (
    <Container>
      <Label>{label}</Label>
      <StyledInput
        ref={ref}
        {...inputProps}
        onFocus={e => { e.target.readOnly = false }}
        onBlur={(e) => {
          onBlur && onBlur(e)
        }}
        onChange={e => { setState({ ...state, [e.target.name]: e.target.value }) }}
        readOnly
        uppercase={uppercase}
        error={error}
      ></StyledInput>
      <ErrorMessage visible={error}>{message}</ErrorMessage>
    </Container>
  )
}

export default Input;

type ReactInput = InputHTMLAttributes<HTMLInputElement>;
type InputArgs = InputProps & Omit<ReactInput, keyof InputProps>

interface InputProps {
  error?: boolean;
  state: any;
  setState: any;
  message?: string;
  label?: string
  endAdornment?: ReactNode
  loading?: boolean
  uppercase?: boolean
}
