import { makeStyles } from "@material-ui/core"

import Button, { ButtonProps } from "../Button"

const SuccessButton = (props: ButtonProps) => {
    const classes = useStyles()

    return (
        <Button {...props} className={classes.greenBackground}>
            Alterações Salvas ;)
        </Button>
    )
}

export default SuccessButton

const useStyles = makeStyles(() => ({
    greenBackground: {
        backgroundColor: '#14E7C4'
    }
}))