import { Link, Route, useRouteMatch } from "react-router-dom";

import {
  Card,
  CardContent,
  Divider,
  Grid,
  GridProps
} from "@material-ui/core";

import clsx from 'clsx'

import Scrollbar from "../../../components/Scrollbar";
import EditPencil from "../../../components/EditPencil";
import DadosAcompanhamentoUpdate from "../../DadosAcompanhamentoUpdate";

import { usePerfilContext } from "../context";

import { Distrito, Escola, Occupation, TurmaDisciplina } from "../../../global";

import { useStyles, Data, DataValue, CardTitle } from "../styles";

const DadosDeAcompanhamento = () => {
  const classes = useStyles();
  const { profile, relationships } = usePerfilContext()
  const { path } = useRouteMatch()

  const GridDatum = (props: GridProps) => (<Grid {...props} item xs={12}></Grid>)

  const renderProfessorRelationships = () => {
    if (!relationships) return null

    if (!relationships.length) return 'Você não está relacionado com nenhuma turma ativa do ano corrente.'

    return relationships?.map(({ id, nome, turmas_disciplinas }: Escola) => (
      <Grid container item key={id}>
        <GridDatum>
          <Data>escola</Data>
        </GridDatum>
        <GridDatum>
          <DataValue>
            {nome}
          </DataValue>
        </GridDatum>
        <GridDatum>
          <Data>turmas/matérias</Data>
        </GridDatum>
        <GridDatum>
          {
            turmas_disciplinas?.map(({ turma, disciplina }) => (
              <DataValue key={`${turma.id}-${disciplina.id}`}>
                {`Turma ${turma.identificador}, ${disciplina.nome}`}
              </DataValue>
            ))
          }
        </GridDatum>
      </Grid>
    ))
  }

  const renderCoordenadorRelationships = () => (
    <Grid container item>
      <GridDatum>
        <Data>escolas</Data>
      </GridDatum>
      <GridDatum>
        {
          relationships?.map(({ id, nome }: Escola) => (
            <DataValue key={id}>
              {nome}
            </DataValue>
          ))
        }
      </GridDatum>
    </Grid>
  )

  const renderAssessorRelationships = () => {
    return relationships?.map(({ id, nome, escolas }: Distrito) => (
      <Grid container item key={id}>
        <GridDatum>
          <Data>distrito</Data>
        </GridDatum>
        <GridDatum>
          <DataValue key={id}>
            {nome}
          </DataValue>
        </GridDatum>
        <GridDatum>
          <Data>escolas</Data>
        </GridDatum>
        <GridDatum>
          {
            escolas.map(({ id, nome }) => (
              <DataValue key={id}>
                {nome}
              </DataValue>
            ))
          }
        </GridDatum>
      </Grid>
    ))
  }

  const renderRelationships = (occupation: number) => {
    switch (occupation) {
      case Occupation.PROFESSOR: return renderProfessorRelationships()
      case Occupation.ASSESSOR: return renderAssessorRelationships()
      default: return renderCoordenadorRelationships();
    }
  }

  return (
    <Card className={clsx(classes.card, classes.dadosAcompanhamentoCard)}>
      <CardContent className={classes.cardContent}>
        <CardTitle>Dados de Acompanhamento</CardTitle>
        <Grid container>
          <Grid item xs={10}>
            <Grid container item>
              <Grid item xs={12}>
                <Data>função</Data>
              </Grid>
              <Grid item xs={12}>
                <DataValue>{profile.occupation_display}</DataValue>
              </Grid>
            </Grid>
          </Grid>
          <Scrollbar
            autoHeight
            autoHeightMax="550px"
            autoHide={false}
          >
            <Divider className={classes.divider}></Divider>
            <Grid container>
              <Grid item xs={10}>
                {renderRelationships(profile.occupation)}
              </Grid>
              <Grid item xs>
                <Link to={`${path}/dados-acompanhamento/update`}>
                  <DataValue className={classes.svgIcon}>
                    <EditPencil />
                  </DataValue>
                </Link>
              </Grid>
            </Grid>
          </Scrollbar>
        </Grid>
      </CardContent>
      <Route path={`${path}/dados-acompanhamento/update`} component={DadosAcompanhamentoUpdate}></Route>
    </Card>
  );
};

export default DadosDeAcompanhamento;

export interface ProfessorRelationship extends Escola {
  turmas_disciplinas: TurmaDisciplina[]
}

