/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect, useCallback } from "react"

import Autocomplete from "../../../../components/Autocomplete"
import Scrollbar from "../../../../components/Scrollbar"
import EscolaCard from "../../../../components/EscolaCard"

import { CadastroContext, CadastroContextType } from "../../../../context/cadastroContext"

import { Disciplina, Escola, EscolaTurmas } from "../../../../global"

const Professor = () => {
    const { state: { selectedEscolas }, handleAddEscola, api, handleAddAllDisciplinas, handleAddAllTurmas, handleAddTurmaDisciplina, handleRemoveEscola, handleRemoveTurmaDisciplina, handleAddAllTurmasDisciplinas } = useContext(CadastroContext) as CadastroContextType

    const [escolas, setEscolas] = useState<Escola[]>()
    const [disciplinas, setDisciplinas] = useState<Disciplina[]>([])
    const [inputValue, setInputValue] = useState('')
    const [escolasTurmas, setEscolasTurmas] = useState<EscolaTurmas[]>([])

    const handleChangeEscola = (_: any, escola: Escola) => handleAddEscola(escola)

    useEffect(() => {
        api.getEscolas({ professor: true })
            .then(({ data }) => setEscolas(data))
            .catch(() => alert('Houve um erro ao buscar as escolas.'))

        api.getDisciplinas()
            .then(({ data }) => setDisciplinas(data))
            .catch(() => alert('Houve um erro ao buscar as escolas.'))
    }, []) /* eslint-disable-line */

    useEffect(() => {
        selectedEscolas.filter((escola) => !escolasTurmas.find((escolaTurma) => escolaTurma.id === escola.id))
            .forEach((escola) => {
                api.getTurmas(escola.id)
                    .then(({ data }) => setEscolasTurmas((escolasTurmas) => [...escolasTurmas, { id: escola.id, turmas: data }]))
                    .catch(() => alert('Houve um erro ao buscar as turmas da escola.'))
            })
    }, [selectedEscolas])

    const removeEscola = (escolaToRemove: Escola) => {
        const newEscolasTurmas = escolasTurmas.filter((escolaTurma) => escolaTurma.id !== escolaToRemove.id)
        setEscolasTurmas(newEscolasTurmas)
        handleRemoveEscola(escolaToRemove)
    }

    const getEscolaCard = useCallback((escola: Escola) => {
        const turmas = escolasTurmas.find((escolaTurmas) => escolaTurmas.id === escola.id)?.turmas || []

        return (
            <EscolaCard key={escola.id} escola={escola} handleAddAllDisciplinas={handleAddAllDisciplinas} handleAddAllTurmas={handleAddAllTurmas} handleAddAllTurmasDisciplinas={handleAddAllTurmasDisciplinas} handleAddTurmaDisciplina={handleAddTurmaDisciplina} handleRemoveEscola={removeEscola} handleRemoveTurmaDisciplina={handleRemoveTurmaDisciplina} turmas={turmas} disciplinas={disciplinas}></EscolaCard>
        )
    }, [escolasTurmas, disciplinas])

    const renderAutoComplete = useCallback(() => {
        if (!escolas) return null

        return escolas.length ? (
            <Autocomplete placeholder="Digite o nome da sua escola" options={escolas} getOptionLabel={(option: Escola) => option.nome}
                label="Adicione a(s) escola(s) em que você trabalha" onChange={handleChangeEscola} inputValue={inputValue} onInputChange={setInputValue}></Autocomplete>
        ) : 'Ainda não há escolas com turmas ativas do ano corrente para você se vincular.'
    }, [escolas])

    return (
        <>
            {renderAutoComplete()}
            <Scrollbar style={{ marginTop: 8 }}>
                {
                    selectedEscolas.map((escola) => getEscolaCard(escola))
                }
            </Scrollbar>
        </>
    )
}

export default Professor

