import { ButtonHTMLAttributes } from 'react'

import BackButton from "./styles"

const Button = ({ children, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <BackButton {...rest}>
        <span>{children}</span>
    </BackButton>
)

export default Button