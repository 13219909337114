import { createContext, useContext } from "react";

import { Profile } from "../../global";

export const PerfilContext = createContext<PerfilContextType | null>(null)

export const usePerfilContext = () => {
    const perfilContext = useContext(PerfilContext) as PerfilContextType
    return perfilContext
}

interface PerfilContextType {
    updateEmail: (email: string) => void
    updateInformacoesBasicas: (profile: Profile) => void
    updateRelationships: (relationships: any) => void
    relationships: any
    profile: Profile
}

