import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './ducks'
import { ProfileStore } from './ducks/profile';
import { AuthStore } from './ducks/auth';

import { REACT_APP_STORAGE_KEY } from '../env';

const persistedReducer = persistReducer(
    {
        key: REACT_APP_STORAGE_KEY!,
        storage,
    },
    rootReducer
)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)

export interface Store {
    auth: AuthStore
    profile: ProfileStore
}