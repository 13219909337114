import styled from 'styled-components'

import professor from '../../assets/professor.png'

export const Container = styled.div`
    @media (max-width: 959px) {
        background: url(${professor}) no-repeat center center;
        background-size: cover;
    }
    height: 100%;
`

export const Embedded = styled.div`
    flex: 1;
    
    @media(max-width: 959px) {
        display: none;
    }

    & .Embed-container {
        height: 100vh;
        border: 0;

        & iframe {
            border: 0;
        }
    }
`

export const Warning = styled.div`
    position: absolute;
    width: 313px;
    left: 23px;
    top: 207px;
    font-size: 24px;
    line-height: 36px;

    color: #FFFFFF;
    
    p {
        margin-top: 20px;
    }    
`;

export const Message = styled.p`
    text-align: center;
    width: 50%;
`

export const MessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`
