import { useSelector } from 'react-redux'

import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { Store } from '../store';

const Routes = () => {
  const token = useSelector((state: Store) => state.auth.token)
  const env = useSelector((state: Store) => state.auth.environment)


  return (token && env ? <PrivateRoutes></PrivateRoutes> : <PublicRoutes></PublicRoutes>);
};

export default Routes;
