import styled from "styled-components";
import { makeStyles } from "@material-ui/core";

export const SelectedTurmaContainer = styled.div`
    display: flex;
    margin-top: 24px;
`

export const TurmaDisciplina = styled.span`
    font-size: 16px;
    flex: 1;
    text-align: left;
`

export const EscolaModalTitle = styled.p`
    font-weight: 500;
    font-size: 24px;
`

export const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
`

export const ContinueEditing = styled.span`
    color: #0295FF;
    text-decoration-line: underline;
    font-size: 16px;
    font-weight: normal;
    padding: 0 15px;
`

export const EscolaModalWarning = styled.p`
    font-weight: 500;
    font-size: 16px;
    margin-top: 8px;
`

export const AutoCompletesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
`

export const RemovingConfirmation = styled.div`
    font-weight: 500;
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 8px;
`

export const RemovingChoice = styled.span`
    cursor: pointer;
`

export const ExcluirLabel = styled.span`
    color: #373748;
    font-size: 14px;
    margin-right: 13px;
    font-weight: bold;
    font-family: Ubuntu, sans-serif;
`

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: 12,
        boxShadow: '1px 2px 5px rgba(55, 55, 72, 0.08)',
        border: '2px solid #A5D9FF',
        overflow: 'unset',
        marginTop: 8
    },
    title: {
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 500
    },
    subheader: {
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 500
    },
    dropdownsContainer: {
        width: '100%'
    },
    iconButton: {
        padding: '0 7px'
    },
    removeEscolamodal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalCard: {
        boxShadow: '2px 8px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: 20,
        width: 380
    },
    modalCardWebWidth: {
        width: 380
    },
    modalCardMobileWidth: {
        width: '85%'
    },
    modalCardHeader: {
        paddingBottom: 0
    },
    modalTitle: {
        fontWeight: 'bold',
        fontSize: 16
    },
    modalCardContent: {
        paddingTop: 0,
        paddingRight: 24,
        paddingLeft: 24
    },
    removeEscolaButton: {
        width: 189
    },
    addButton: {
        marginTop: 24
    }
}))

export default useStyles