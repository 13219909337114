import styled from 'styled-components'

export const MaskedInputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;

    @media(max-width: 959px) {
        display: initial;
    }
`