import { useState, useContext } from "react"
import { Accordion, AccordionDetails, AccordionProps as MuiAccordionProps, Checkbox, IconButton } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';

import { CadastroContext, CadastroContextType } from "../../../../../context/cadastroContext";

import { Escola, SelectedDistrito } from "../../../../../global"

import useStyles, { NomeEscola, EscolaContainer, Distrito, Hint, ExcluirLabel, AccordionSummary, RemovingConfirmation, RemovingChoice } from "./styles"
import { arrayContainsObject } from "../../../../../utils";

const DistritoAccordion = ({ selectedDistrito, ...rest }: AccordionProps) => {
    const { selectedEscolas } = selectedDistrito

    const classes = useStyles()

    const { allEscolasAreSelected, handleRemoveDistrito, handleAddEscolaDistrito, handleRemoveEscolaDistrito, handleAddAllEscolasDistrito, handleRemoveAllEscolasDistrito } = useContext(CadastroContext) as CadastroContextType

    const [expanded, setExpanded] = useState(false)
    const [requestedRemoving, setRequestedRemoving] = useState(false)

    const deleteDistritoAction = (
        <IconButton onClick={() => setRequestedRemoving(true)} className={classes.deleteButton}>
            <ExcluirLabel>Excluir</ExcluirLabel>
            <CloseIcon></CloseIcon>
        </IconButton>
    )

    const handleCheck = (checked: boolean, escola: Escola, oldDistrito: SelectedDistrito) => checked ? handleAddEscolaDistrito(escola, oldDistrito) : handleRemoveEscolaDistrito(escola, oldDistrito)
    const handleCheckAll = (checked: boolean) => checked ? handleAddAllEscolasDistrito(selectedDistrito) : handleRemoveAllEscolasDistrito(selectedDistrito)

    const confirmDistritoRemoving = (
        <RemovingConfirmation>
            <span>Deseja mesmo excluir?</span>
            <RemovingChoice onClick={() => handleRemoveDistrito(selectedDistrito)}>Sim</RemovingChoice>
            <RemovingChoice onClick={() => setRequestedRemoving(false)}>Não</RemovingChoice>
        </RemovingConfirmation>
    )

    return (
        <Accordion {...rest} expanded={expanded} className={classes.root}>
            <AccordionSummary expanded={expanded}>
                <Distrito onClick={() => setExpanded(!expanded)}>{selectedDistrito.distrito.nome}</Distrito>
                {requestedRemoving ? confirmDistritoRemoving : deleteDistritoAction}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Hint>Selecione abaixo a(s) escola(a)s que você acompanha</Hint>
                <EscolaContainer>
                    <NomeEscola>SELECIONAR TODAS AS ESCOLAS</NomeEscola>
                    <Checkbox className={classes.checkbox} checked={allEscolasAreSelected(selectedDistrito)} onChange={(_, checked) => handleCheckAll(checked)}></Checkbox>
                </EscolaContainer>
                {selectedDistrito.distrito.escolas.map((escola) => (
                    <EscolaContainer key={escola.id}>
                        <NomeEscola>{escola.nome}</NomeEscola>
                        <Checkbox className={classes.checkbox} checked={arrayContainsObject(selectedEscolas, escola)} onChange={(_, checked) => handleCheck(checked, escola, selectedDistrito)}></Checkbox>
                    </EscolaContainer>
                ))}
            </AccordionDetails>
        </Accordion>
    )
}

export default DistritoAccordion

interface AccordionProps extends MuiAccordionProps {
    selectedDistrito: SelectedDistrito
}