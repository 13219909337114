/* eslint-disable react-hooks/exhaustive-deps */
import { Link, Route, useRouteMatch } from "react-router-dom";

import {
  Card,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";

import clsx from 'clsx'

import UserEmailUpdate from "../../UserEmailUpdate";
import UserPasswordUpdate from "../../UserPasswordUpdate";
import EditPencil from "../../../components/EditPencil";

import { CardTitle, ChangePassword, Label, useStyles, Value } from "../styles";


const Usuario = ({ email, username }: UsuarioProps) => {
  const classes = useStyles();
  const { path } = useRouteMatch()

  return (
    <Card className={clsx(classes.card, classes.usuarioCard)}>
      <CardContent className={classes.cardContent} classes={{
        root: classes.noPaddingBottom
      }}>
        <CardTitle>Usuário</CardTitle>
        <Grid container>
          <Grid item xs={10}>
            <Grid container item>
              <Grid container xs={12}>
                <Label>email</Label>
              </Grid>
              <Grid container xs={12}>
                <Value>{email}</Value>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Link to={`${path}/email/update`}>
              <Value
                className={classes.svgIcon}>
                <EditPencil />
              </Value>
            </Link>
          </Grid>
        </Grid>
        <Divider className={classes.divider}></Divider>
        <Grid container>
          <Grid item xs={10}>
            <Grid container item>
              <Grid container xs={12}>
                <Label>usuario</Label>
              </Grid>
              <Grid container xs={12}>
                <Value>{username}</Value>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid container xs={12}>
                <Label>senha</Label>
              </Grid>
              <Grid container xs={12}>
                <Value>.........</Value>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container alignItems="flex-end" justifyContent="flex-end">
              <Link to={`${path}/password/update`}>
                <ChangePassword>
                  Mudar senha
                </ChangePassword>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Route path={`${path}/email/update`} component={UserEmailUpdate}></Route>
      <Route path={`${path}/password/update`} component={UserPasswordUpdate}></Route>
    </Card>
  );
};

export default Usuario;

interface UsuarioProps {
  email: string
  username: string
}