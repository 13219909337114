import { createGlobalStyle } from 'styled-components'
import { createTheme } from '@material-ui/core/styles';

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        user-select: none;
        user-drag: none;
        font-weight: 500;
    }

    a {
        text-decoration:none;
    }

    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
        font-family: "Ubuntu", sans-serif;
    }

    img,
    svg {
        display: block;
        max-width: 100%;
    }

    input {
        border: 0;
    }
`;

export const MuiTheme = createTheme({
    palette: {
        primary: {
            main: "#0295FF",
        },
    },
    typography: {
        fontFamily: 'Ubuntu'
    },
    overrides: {
        MuiStepIcon: {
            root: {
                color: '#A5D9FF',
                fontWeight: 500
            }
        },
        MuiStep: {
            horizontal: {
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        MuiStepLabel: {
            iconContainer: {
                paddingRight: 0
            }
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 8,
                height: 48,
                marginTop: 8
            }
        },
        MuiAccordion: {
            rounded: {
                '&:last-child': {
                    borderBottomLeftRadius: 12,
                    borderBottomRightRadius: 12,
                },
                '&:first-child': {
                    borderTopRightRadius: 12,
                    borderTopLeftRadius: 12,
                }
            }
        },
        MuiInputBase: {
            input: {
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            }
        }
    }
});

export interface Environment {
    id: number
    name: string
    domain_url: string
}

export interface Funcao {
    role: number
    description: string
}

export interface Escola {
    id: number
    nome: string
    turmas_disciplinas?: TurmaDisciplina[]
}

export interface Turma {
    id: number
    identificador: string
    serie?: number
}

export interface Disciplina {
    id: number
    nome: string
}

export interface SelectedEscola {
    escola: Escola
    selectedTurmasDisciplinas: SelectedTurmaDisciplina[]
}

export interface SelectedTurmaDisciplina {
    turma: Turma
    disciplina: Disciplina
}

export interface TurmaDisciplina {
    turma: Turma
    disciplina: Disciplina
}

export enum Occupation {
    PROFESSOR = 0,
    GESTOR = 1,
    COORDENADOR = 2,
    PEDAGOGO = 3,
    ASSESSOR = 4
}

export interface Distrito {
    id: number
    nome: string
    escolas: Escola[]
}

export interface SelectedDistrito {
    distrito: Distrito
    selectedEscolas: Escola[]
}

export interface Profile {
    email: string
    username: string
    nome: string
    occupation: number
    occupation_display: string
    cpf?: string
    telefone_celular?: string
    data_nascimento?: string
}

export interface ProfileUpdatableData {
    nome?: string
    telefone_celular?: string
    data_nascimento?: string
    email?: string
}

export interface EscolaTurmas {
    id: number
    turmas: Turma[]
}

export interface DistritoAutocomplete extends Distrito {
    selected: boolean
}

export type CadastroState = {
    step: number
    finishedRegister: boolean
    funcao?: Funcao
    environment?: Environment
    selectedEscolas: Escola[]
    selectedDistritos: SelectedDistrito[]
    distritos: Distrito[]
    username: string
    email: string
    password: string
    confirmation: string
    nome: string
    cpf: string
    telefone: string
    data_nascimento: Date | null
}