import { useState, useContext, useEffect } from "react"

import Autocomplete from "../../../../components/Autocomplete"
import Scrollbar from "../../../../components/Scrollbar"

import { CadastroContext, CadastroContextType } from "../../../../context/cadastroContext"

import { Escola } from "../../../../global"
import EscolaCard from "./EscolaCard"

import useStyles from "./styles"

const Coordenador = () => {
    const classes = useStyles()

    const noEscola = {
        id: 0,
        nome: ''
    }

    const { handleAddEscola, api, state: { selectedEscolas } } = useContext(CadastroContext) as CadastroContextType

    const [escolasAutocomplete, setEscolasAutocomplete] = useState<Escola[]>([])
    const [, setEscolas] = useState<Escola[]>([])
    const [escola, setEscola] = useState<Escola>(noEscola)

    const handleChangeEscola = (_: any, escola: Escola) => {
        handleAddEscola(escola)
        setEscola(noEscola)
    }

    useEffect(() => {
        api.getEscolas()
            .then(({ data }) => {
                setEscolas(data)
                setEscolasAutocomplete([
                    noEscola,
                    ...data
                ])
            })
            .catch(() => alert('Houve um erro ao buscar as escolas.'))
    }, []) /* eslint-disable-line */

    return (
        <>
            <Autocomplete className={classes.autocomplete} placeholder="Digite o nome da sua escola"
                options={escolasAutocomplete} getOptionLabel={(option: Escola) => option.nome} value={escola}
                label="Adicione a(s) escola(s) em que você trabalha" onChange={handleChangeEscola}></Autocomplete>
            <Scrollbar>
                {
                    selectedEscolas.map((escola) => (
                        <EscolaCard key={escola.id} escola={escola}></EscolaCard>
                    ))
                }
            </Scrollbar>
        </>
    )
}

export default Coordenador