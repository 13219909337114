import styled from 'styled-components'

import loginPageBackground from '../../assets/login-page-background.png'
import logo from '../../assets/spe-logo-horizontal.svg'

export const Container = styled.div`
    background: url(${loginPageBackground}) no-repeat center center fixed;
    background-size: cover;
    height: 100vh;

    @media(max-width: 959px) {
        padding-top: 117px;
    }
`

export const SignUpRedirectContainer = styled.div`
    color: white;
    width: 313px;
    margin: 274px auto 0;
    font-weight: 700;
`

export const Heading1 = styled.h1`
    margin-bottom: 16px;
`

export const Heading2 = styled.h2`
    margin-bottom: 16px;
    line-height: 42px;
`

export const LoginForm = styled.form`
    /* width: 343px; */
    margin: auto;
    display: grid;
    row-gap: 24px;

    @media(max-width: 959px) {
        label {
            color: white;
        }
    }
`

export const ErrorMessage = styled.p<{ visible: boolean }>`
    color: #FC4821;
    font-weight: 500;
    text-align: center;
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

export const UnderlineLink = styled.p`
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    text-align: center;
    cursor: pointer;
`

export const CreateAccount = styled(UnderlineLink)`
    text-decoration-line: none;
    display: none;

    @media(max-width: 959px) {
        display: initial;
    }
`

export const MobileLinks = styled.div`
    padding-top: 20px;
    row-gap: 24px;
    color: #373748;

    @media(max-width: 959px) {
        display: flex;
        flex-direction: column;
        color: white;
    }
`

export const Logo = styled.img.attrs(() => ({
    alt: 'Super Logo',
    src: logo
}))``

export const FormTitle = styled.p`
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    @media(max-width: 959px) {
        color: white;
    }
`

export const AppTitle = styled.p`
    text-align: center;
    font-size: 36px;
    font-weight: 700;

    @media(max-width: 959px) {
        color: white;
    }
`
