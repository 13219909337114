import { ReactNode } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';

import useStyles, { Container, Label, ErrorMessage } from './styles'

const CustomAutoComplete = ({ containerClassName, label, placeholder, onInputChange, error, message, ...autocompleteProps }: CustomAutocompleteProps<any>) => {
    const classes = useStyles()

    return (
        <Container className={containerClassName}>
            <Label>{label}</Label>
            <Autocomplete {...autocompleteProps} noOptionsText="Carregando..." popupIcon={<ExpandMoreIcon></ExpandMoreIcon>} onInputChange={onInputChange && ((_: any, value) => onInputChange(value))}
                renderInput={(params) => <TextField classes={{ root: classes.root }} {...params} placeholder={placeholder} variant="outlined"></TextField>}
                disableClearable={true}
            ></Autocomplete>
            <ErrorMessage visible={error}>{message}</ErrorMessage>
        </Container>

    )
}

export default CustomAutoComplete

interface CustomAutocompleteProps<T> {
    label: string
    placeholder: string
    options: T[]
    getOptionLabel?: (option: T) => string
    className?: any
    onChange?: any
    defaultValue?: any
    value?: any
    inputValue?: string
    onInputChange?: (newInputValue: string) => void
    containerClassName?: any
    error?: boolean
    message?: string
    disableCloseOnSelect?: boolean
    disabledItemsFocusable?: boolean
    renderOption?: (option: T, state: object) => ReactNode
}