import styled from 'styled-components'

import perfilBg from '../../assets/perfil-bg.png'

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import UnderlinedTextButton from '../../components/UnderlinedTextButton'

export const useStyles = makeStyles((theme: Theme) => createStyles({
    divider: {
        backgroundColor: '#303042',
        marginBottom: 24,
    },
    card: {
        width: '100%',
        maxWidth: 700,
        boxShadow: '2px 8px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: 20,
        padding: 24,
        paddingBottom: 8,
    },
    cardContent: {
        padding: 0,
        color: '#373748',
        fontWeight: 500,
    },
    svgIcon: {
        fill: 'none',
        cursor: 'pointer',
        textAlign: 'end',
    },
    modalCardHeader: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: 24,
            paddingTop: 14,
            paddingBottom: 0,
            flex: 'inherit'
        },
    },
    ModalCard: {
        width: 615,
        padding: 24,
        paddingBottom: 44,
        boxShadow: '2px 8px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: 30,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            padding: 0,
        },
    },
    modalCardContent: {
        paddingBottom: 0,
        paddingLeft: 32,
        paddingRight: 32,
        display: 'grid',
        rowGap: 24,
        [theme.breakpoints.down('sm')]: {
            rowGap: 16,
        }
    },
    gridDistrito: {
        paddingBottom: 0
    },
    gridDatum: {
        marginBottom: 24
    },
    autocomplete: {
        margin: '24px 0'
    },
    dadosAcompanhamentoCard: {
        gridArea: 'da'
    },
    usuarioCard: {
        gridArea: 'user'
    },
    infosBasicasCard: {
        gridArea: 'ib'
    },
    noPaddingBottom: {
        '&:last-child': {
            paddingBottom: 0
        }
    }
}))

export const Container = styled.div`
    height: 100vh;
    
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .7)), url(${perfilBg}) no-repeat center center fixed;
    background-blend-mode: multiply, normal;
    background-size: cover;

    color: white;

    @media (max-width: 959px) {
        background: linear-gradient(90deg, #078CFF 0%, #14E7DB 100%);
        flex-direction: column;
    }

    @media (min-width: 1290px) {
        .MuiGrid-grid-xs-12 {
            flex-grow: 0;
            max-width: 41.666667%;
            flex-basis: 41.666667%;
        }
    }
`

export const Content = styled.div`
    color: white;
    width: 100%;
    padding: 34px 54px 54px;

    @media (max-width: 959px) {
        padding: 27px;
    }
`

export const Title = styled.h1`
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;

    @media (max-width: 959px) {
        font-size: 18px;
    }
`

export const Subtitle = styled.p`
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 30px;

    @media (max-width: 959px) {
        font-size: 14px;
    }
`

export const CardTitle = styled.p`
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.01em;
    margin-bottom: 16px;

    @media (max-width: 959px) {
        font-size: 16px;
    }
`

export const Label = styled.p`
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    padding-bottom: 24px;
    color: #6B6878;

    @media (max-width: 959px) {
        font-size: 12px;
    }

    @media (max-width: 1290px) {
            padding-bottom: 4px;
    }
`

export const Data = styled.p`
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    padding-bottom: 24px;
    color: #6B6878;

    @media (max-width: 959px) {
        font-size: 12px;
    }

    @media (max-width: 1290px) {
            padding-bottom: 4px;
    }
`

export const Value = styled.p`
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;

    @media (max-width: 959px) {
        padding-bottom: 16px;
    }

    @media (max-width: 375px) {
        font-size: 12px;
    }
`

export const DataValue = styled.p`
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;

    @media (max-width: 959px) {
        padding-bottom: 16px;
    }

    @media (max-width: 375px) {
        font-size: 12px;
    }
`

export const ChangePassword = styled(UnderlinedTextButton)`
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    padding: 0 12px;

    @media (max-width: 1452px) {
        font-size: 12px;
    }
`

export const ModalTitle = styled.p`
    padding: 0;
    font-weight: 500;
    font-size: 24px;
    color: #373748;

    @media (max-width: 959px) {
        font-size: 18px;
        display: flex;
        align-items: center;
    }
`

export const MaskedInputContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    @media (max-width: 959px) {
        grid-template-columns: auto;
    }
`

export const CardsContainer = styled.div`
    display: grid;
    grid-template-areas:
    "user da"
    "ib da";
    gap: 32px;

    @media(max-width: 959px) {
        grid-template-areas:
            "user"
            "ib"
            "da"; 
    }
`