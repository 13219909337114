import { useState, useContext } from "react";
import { Card, CardContent, CardHeader, CardProps, IconButton, useMediaQuery } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx'

import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import UnderlinedTextButton from "../../../../../components/UnderlinedTextButton";

import { CadastroContext, CadastroContextType } from "../../../../../context/cadastroContext";

import { Escola } from "../../../../../global";

import useStyles, { EscolaModalTitle, EscolaModalWarning, ModalButtonsContainer } from "./styles"

const EscolaCard = ({ escola, ...rest }: EscolaCardProps) => {
    const isMobile = useMediaQuery('(max-width: 959px)')

    const classes = useStyles()
    const { handleRemoveEscola } = useContext(CadastroContext) as CadastroContextType

    const [removeEscolaModalIsOpen, setRemoveEscolaModalIsOpen] = useState(false)

    const removeEscola = () => {
        handleRemoveEscola(escola)
        setRemoveEscolaModalIsOpen(false)
    }

    const closeRemoveEscolaModal = () => setRemoveEscolaModalIsOpen(false)

    const closeAction = (onClick?: () => void) => (
        <IconButton onClick={onClick}>
            <CloseIcon></CloseIcon>
        </IconButton>
    )

    const titleStyles = { className: classes.title }

    return (
        <>
            <Card {...rest} className={classes.root}>
                <CardHeader action={closeAction(() => setRemoveEscolaModalIsOpen(true))} title={escola.nome} titleTypographyProps={titleStyles}>
                </CardHeader>
            </Card>
            <Modal open={removeEscolaModalIsOpen}>
                <Card className={clsx(classes.modalCard, isMobile ? classes.modalCardMobileWidth : classes.modalCardWebWidth)}>
                    <CardHeader className={classes.modalCardHeader} action={closeAction(closeRemoveEscolaModal)}>
                    </CardHeader>
                    <CardContent className={classes.modalCardContent}>
                        <EscolaModalTitle>Deseja mesmo excluir escola?</EscolaModalTitle>
                        <EscolaModalWarning>Os dados que você adicionou serão apagados</EscolaModalWarning>
                        <ModalButtonsContainer>
                            <UnderlinedTextButton onClick={closeRemoveEscolaModal}>Voltar a editar</UnderlinedTextButton>
                            <Button className={classes.removeEscolaButton} onClick={removeEscola}>Excluir</Button>
                        </ModalButtonsContainer>
                    </CardContent>
                </Card>
            </Modal>
        </>
    )
}

export default EscolaCard

interface EscolaCardProps extends CardProps {
    escola: Escola
}