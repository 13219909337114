import styled, { css } from 'styled-components'

export const StyledButton = styled.button`
    color: white;
    width: 100%;
    height: 51px;
    font-weight: 700;
    font-size: 18px;
    font-family: Ubuntu, sans-serif;
    border-radius: 12px;
    background-color: #0295FF;
    border: 0;
    opacity: 0.5;
    ${props => !props.disabled &&
        css`
        opacity: 1;
        cursor: pointer;
    `}
    transition-property: opacity, transform;
    transition-duration: .25s;
    :hover, active {
        transform: scale(.9);
    }
`