import Menu from '../Menu'

import { Container, ChildrenContainer } from './styles'

const InnerAppContainer = ({ children }: any) => {
    return (
        <Container>
            <Menu></Menu>
            <ChildrenContainer>
                {children}
            </ChildrenContainer>
        </Container>
    )
}

export default InnerAppContainer