import styled from 'styled-components'
import { makeStyles, createStyles } from '@material-ui/core'

export const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
`

export const ContinueEditing = styled.span`
    color: #0295FF;
    text-decoration-line: underline;
    font-size: 16px;
    font-weight: normal;
    padding: 0 15px;
`

export default makeStyles(() => createStyles({
    autocomplete: {
        marginBottom: 8
    },
    modalCard: {
        boxShadow: '2px 8px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: 20
    },
    modalCardWebWidth: {
        width: 380
    },
    modalCardMobileWidth: {
        width: '85%'
    },
    modalCardHeader: {
        paddingBottom: 0
    },
    modalCardContent: {
        paddingTop: 0,
        paddingRight: 24,
        paddingLeft: 24
    },
    removeEscolaButton: {
        width: 189
    }
}))