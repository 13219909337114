import styled from "styled-components";
import { makeStyles, createStyles } from '@material-ui/core'

export const Container = styled.div`
    width: 100%;
    text-align: left;
`

export const Label = styled.label`
    font-size: 14px;
    color: #373748;
    margin-bottom: 8px;
`

export const ErrorMessage = styled.p<{ visible?: boolean }>`
    color: #FC4821;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    font-size: 12px;
`

export default makeStyles(() => createStyles({
    root: {
        borderRadius: 8,
        marginBottom: 8
    }
}))