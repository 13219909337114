import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import Input from "../../components/Input";
import PasswordInput from "../../components/PasswordInput";
import Button from "../../components/Button";

import { AuthCreators } from "../../store/ducks/auth";
import { ProfileCreators } from "../../store/ducks/profile";

import { getEnvironment, protocol } from "../../utils";

import { AppTitle, CreateAccount, ErrorMessage, FormTitle, LoginForm, Logo, MobileLinks, UnderlineLink } from "./styles";

import { Environment } from '../../global'

const Login = ({ toggleScreen }: LoginProps) => {
    const dispatch = useDispatch();
    const history = useHistory()

    type State = {
        username: string
        password: string
    }

    const [state, setState] = useState<State>({
        username: '',
        password: ''
    })

    const [loginIsProcessing, setLoginIsProcessing] = useState(false);
    const [error, setError] = useState(false);

    const { password, username } = state

    const signIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!loginIsProcessing) {
            try {
                setLoginIsProcessing(true);

                const env: Environment = await getEnvironment(username);

                if (!env) throw new Error("");

                const apiRootUrl = `${protocol}://${env.domain_url}/api`

                const authResponse = await axios.post(`${apiRootUrl}/token/`, { username, password })
                const profileResponse = await axios.get(`${apiRootUrl}/user/profile/data/`, { headers: { Authorization: `Bearer ${authResponse.data.access}` } })

                const { data: { access } } = authResponse
                const { data: profileData } = profileResponse

                dispatch(AuthCreators.setToken(access));
                dispatch(AuthCreators.setEnvironment(env));
                dispatch(ProfileCreators.setProfile(profileData))

                setLoginIsProcessing(false);
            } catch (error) {
                setError(true);
                setLoginIsProcessing(false);
                alert('Houve um erro ao tentar realizar o login')
            }
        }
    };

    const handleChangePassword = (value: string) => {
        if (error) setError(false);

        setState({ ...state, password: value });
    };

    const loginButtonIsEnabled = username && password;

    useEffect(() => {
        setError(false)
    }, [state])

    return (
        <LoginForm onSubmit={signIn}>
            <Logo></Logo>
            <AppTitle>Portal Super Ensino</AppTitle>
            <FormTitle>Acessar conta</FormTitle>
            <ErrorMessage visible={error}>
                Login ou senha incorretos
            </ErrorMessage>
            <Input
                error={error}
                label="Usuário"
                placeholder="Digite seu usuário"
                state={state}
                setState={setState}
                name="username"
            >
            </Input>
            <PasswordInput
                error={error}
                label="Senha"
                placeholder="Digite sua senha"
                value={password}
                onChange={(e) => handleChangePassword(e.target.value)}
            ></PasswordInput>
            <Button disabled={!loginButtonIsEnabled} loading={loginIsProcessing}>
                ENTRAR
            </Button>
            <MobileLinks>
                <UnderlineLink onClick={() => history.push('/recuperar-senha')}>
                    Esqueci minha senha
                </UnderlineLink>
                <CreateAccount>
                    Não tem uma conta?{" "}
                    <UnderlineLink as="span" onClick={toggleScreen}>Criar Conta</UnderlineLink>
                </CreateAccount>
            </MobileLinks>
        </LoginForm>
    )
};

export default Login;

interface LoginProps {
    toggleScreen: () => void
}