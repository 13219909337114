import { useContext, useEffect, useMemo, useState } from "react"

import Autocomplete from "../../../../components/Autocomplete"
import Scrollbar from "../../../../components/Scrollbar"
import DistritoAccordion from "./DistritoAccordion"

import { CadastroContext, CadastroContextType } from "../../../../context/cadastroContext"

import { Distrito } from "../../../../global"
import { Checkbox, ListItem, ListItemText } from "@material-ui/core"
import Button from "../../../../components/Button"
import { updateObjectsArray } from "../../../../utils"

const Assessor = () => {
    const { handleAddDistritos, api, handleSetDistritos, state: { distritos, selectedDistritos } } = useContext(CadastroContext) as CadastroContextType

    const handleChangeDistrito = (_: any, distrito: Distrito) => { }

    const [inputValue, setInputValue] = useState('')
    const [distritosAutocomplete, setDistritosAutocomplete] = useState<DistritoAutocomplete[]>([])

    const ALL_ID = -1
    const NO_ID = 0

    const noDistrito = {
        id: NO_ID,
        nome: '',
        escolas: []
    }

    const allDistritos = {
        id: ALL_ID,
        nome: 'Todos',
        escolas: []
    }

    useEffect(() => {
        if (!distritosAutocomplete.length)
            api.getDistritos()
                .then(({ data }) => {
                    handleSetDistritos(data)

                    const distritosAutocomplete = [noDistrito, allDistritos, ...data].map((distrito) => ({ ...distrito, selected: false }))

                    setDistritosAutocomplete(distritosAutocomplete)
                })
                .catch(() => alert('Houve um erro ao buscar os distritos.'))
    }, []) /* eslint-disable-line */

    const uncheckAllCheckboxes = () => {
        const distritosAutocomplete = [noDistrito, allDistritos, ...distritos].map((distrito) => ({ ...distrito, selected: false }))
        setDistritosAutocomplete(distritosAutocomplete)
    }

    const renderDistritoOption = (distrito: DistritoAutocomplete) => {
        if (distrito.id) {

            const handleCheck = (checked: boolean) => {
                if (distrito.id === ALL_ID) {
                    const newDistritosAutocomplete = distritosAutocomplete.map((distrito) => ({ ...distrito, selected: checked }))
                    setDistritosAutocomplete(newDistritosAutocomplete)
                    return
                }

                const newDistritosAutocomplete = updateObjectsArray([...distritosAutocomplete], distrito, { ...distrito, selected: checked })
                setDistritosAutocomplete(newDistritosAutocomplete)
            }

            return (
                <ListItem
                    onClick={() => handleCheck(!distrito.selected)}
                >
                    <Checkbox
                        checked={distrito.selected}
                        color="primary"
                    />
                    <ListItemText primary={distrito.nome} />
                </ListItem>
            )
        }

        return null
    }

    const addDistritos = () => {
        const filterDistrito = (distrito: DistritoAutocomplete) => distrito.selected && distrito.id > 0

        const mapDistrito = ({ id, nome, escolas }: DistritoAutocomplete) => ({ id, nome, escolas })

        const selectedDistritos = distritosAutocomplete.filter(filterDistrito).map(mapDistrito)

        handleAddDistritos(selectedDistritos)

        uncheckAllCheckboxes()
    }

    const addButtonIsEnabled = useMemo(() => distritosAutocomplete.find((distrito) => distrito.selected), [distritosAutocomplete])

    return (
        <>
            <Autocomplete placeholder="Digite o nome do distrito" options={distritosAutocomplete} getOptionLabel={(option: Distrito) => option.nome}
                label="Adicione o(s) distrito(s) em que você trabalha" onChange={handleChangeDistrito} renderOption={renderDistritoOption} inputValue={inputValue} onInputChange={setInputValue} disableCloseOnSelect={true} disabledItemsFocusable={true}></Autocomplete>
            <Button disabled={!addButtonIsEnabled} onClick={addDistritos}>Adicionar</Button>
            <Scrollbar style={{ marginTop: 8 }}>
                <div></div>
                {
                    selectedDistritos.map((selectedDistrito) => (
                        <DistritoAccordion key={selectedDistrito.distrito.id} selectedDistrito={selectedDistrito}><></></DistritoAccordion>
                    ))
                }
                <div></div>
            </Scrollbar>
        </>
    )
}

export default Assessor

interface DistritoAutocomplete extends Distrito {
    selected: boolean
}