import { Snackbar as MuiSnackbar, SnackbarProps } from '@material-ui/core'
import { SnackbarContainer, useStyles } from "./styles"

const Snackbar = (props: SnackbarProps) => {
    const classes = useStyles()

    return (
        <SnackbarContainer>
            <MuiSnackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={4000}
                {...props}
            ></MuiSnackbar>
        </SnackbarContainer>
    )
}

export default Snackbar