import { useDispatch } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Card,
  CardContent,
  CardHeader,
  Drawer,
  SvgIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  useMediaQuery,
  Divider
} from "@material-ui/core";

import clsx from 'clsx'

import Modal from "../Modal";

import { AuthCreators } from "../../store/ducks/auth";
import { ProfileCreators } from "../../store/ducks/profile";

import { ReactComponent as dados } from "../../assets/icons/dados.svg";
import { ReactComponent as perfil } from "../../assets/icons/perfil.svg";
import { ReactComponent as acesso } from "../../assets/icons/acesso.svg";
import { ReactComponent as sair } from "../../assets/icons/sair.svg";
import { ReactComponent as menuIcon } from "../../assets/icons/menuIcon.svg";
import { ReactComponent as closeIcon } from "../../assets/icons/closeIcon.svg";

import * as _ from "./styles";

const Menu = () => {
  const classes = _.useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width: 959px");

  const logout = () => {
    dispatch(AuthCreators.logout());
    dispatch(ProfileCreators.reset());
    history.push("/");
  };

  const goToPerfil = () => {
    history.push("/perfil");
  };

  const goToDados = () => {
    history.push("/");
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalItem, setModalItem] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleClickAcesso = () => {
    setModalItem("acesso");
    setModalIsOpen(true);
  };

  const handleClickLogout = () => {
    setModalItem("logout");
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const ListItemLink = (props: ListItemProps<"a", { button?: true }>) => (
    <ListItem button component="a" {...props} />
  );

  const menuItems = [
    {
      href: '/',
      component: dados,
      label: 'Dados'
    },
    {
      href: '/perfil',
      component: perfil,
      label: 'Perfil'
    },
    {
      component: acesso,
      label: 'Acesso',
      handleClick: handleClickAcesso
    },
    {
      component: sair,
      label: 'Sair',
      handleClick: handleClickLogout
    },
  ]

  return (
    <>
      {modalItem === "logout" ? (
        <Modal open={modalIsOpen} onClose={handleModalClose} className={clsx(isMobile && classes.modalMobile)}>
          <Card className={clsx(classes.modalCard, !isMobile && classes.positionAbsolute)}>
            <CardHeader className={classes.modalCardHeader}></CardHeader>
            <CardContent className={classes.modalCardContent}>
              <div>Deseja sair do Portal?</div>
              <_.ModalButtonsContainer>
                <_.ModalButton1 onClick={handleModalClose}>
                  Não
                </_.ModalButton1>
                <_.ModalButton2 onClick={logout}>Sair</_.ModalButton2>
              </_.ModalButtonsContainer>
            </CardContent>
          </Card>
        </Modal>
      ) : (
        <Modal open={modalIsOpen} onClose={handleModalClose}>
          <_.CardAcesso>
            <>
              <_.ModalAcessoSuperEnsinoGamesIcon />
              <_.GotoSuperEnsinoGames onClick={() => setModalIsOpen(false)}>
                Ir para o Super Ensino Games
              </_.GotoSuperEnsinoGames></>
            <>
              <_.ModalAcessoSuperAlunoIcon />
              <_.GotoSuperAluno onClick={() => setModalIsOpen(false)}>
                Ir para o Super Aluno
              </_.GotoSuperAluno>   </>
          </_.CardAcesso>
        </Modal>
      )}
      {isMobile ? (
        <>
          <_.MenuMobile style={menuIsOpen ? { zIndex: 1301 } : { zIndex: 1200 }}>
            {!menuIsOpen ? (
              <SvgIcon
                className={classes.menuIcon}
                onClick={() => setMenuIsOpen(true)}
                component={menuIcon}
              ></SvgIcon>
            ) : (
              <SvgIcon
                className={classes.menuIcon}
                onClick={handleMenuClose}
                component={closeIcon}
              ></SvgIcon>
            )}
            <_.LogoMobile />
            <_.ItemsMobile>
              {[
                { icon: dados, text: "Dados", to: goToDados },
                { icon: perfil, text: "Perfil", to: goToPerfil },
              ].map((item) => (
                <_.ItemMobile onClick={item.to}>
                  <SvgIcon
                    className={classes.svgIcon}
                    component={item.icon}
                  ></SvgIcon>
                  {item.text}
                </_.ItemMobile>
              ))}
            </_.ItemsMobile>
          </_.MenuMobile>
          <Drawer
            open={menuIsOpen}
            onClose={handleMenuClose}
            anchor="top"
            variant="temporary"
            classes={{
              paper: classes.drawerPaperMobile,
            }}
          >
            <_.DrawerContent className={classes.drawerContentMobile}>
              <List className={classes.listMobile}>
                {[
                  {
                    text: "Acesso Super Aluno",
                    icon: <_.AcessoSuperAluno />,
                    link: "https://aluno.superensino.com.br/",
                  },
                  {
                    text: "Youtube",
                    icon: <_.Youtube />,
                    link: "https://www.youtube.com/channel/UC2cU1M0CZKu14knTYc_pp7g",
                  },
                  {
                    text: "Instagram",
                    icon: <_.Instagram />,
                    link: "https://www.instagram.com/superensino/?hl=pt-br",
                  },
                  {
                    text: "Facebook",
                    icon: <_.Facebook />,
                    link: "https://www.facebook.com/go.superensino",
                  },
                  {
                    text: "Linkedin",
                    icon: <_.LinkedIn />,
                    link: "https://www.linkedin.com/company/super-ensino/mycompany/",
                  },
                ].map((item, index) => (
                  <ListItemLink
                    target="_blank"
                    href={item.link}
                    button
                    key={index}
                    className={classes.listItemMobile}
                  >
                    <ListItemIcon className={classes.ListItemIconMobile}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.text}
                    />
                  </ListItemLink>
                ))}
                <Divider style={{ marginBottom: 20 }}></Divider>
                <ListItemLink
                  target="_blank"
                  button
                  className={classes.listItemMobile}
                  onClick={handleClickLogout}
                >
                  <ListItemIcon className={classes.ListItemIconMobile}>
                    <_.Sair></_.Sair>
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary="Sair"
                  />
                </ListItemLink>
              </List>
            </_.DrawerContent>
          </Drawer>
        </>
      ) : (
        <_.Container>
          <_.Container2>
            <_.Logo />
            <_.MenuItems>
              {
                menuItems.map(({ href, component, label, handleClick }, index) => (
                  <_.Item key={`mitem-${index}`} href={href} onClick={handleClick} isActive={window.location.pathname === href}>
                    <SvgIcon component={component} /> {label}
                  </_.Item>
                ))
              }
            </_.MenuItems>
            <_.SocialMediaContainer>
              <_.Youtube />
              <_.Instagram />
              <_.Facebook />
              <_.LinkedIn />
            </_.SocialMediaContainer>
          </_.Container2>
        </_.Container>
      )}
    </>
  )
};
export default Menu;
